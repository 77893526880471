import React, { useEffect, useState, useRef } from 'react'
import "./AskFollowUp.css";
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LinkIcon from '@mui/icons-material/Link';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import ReplayIcon from '@mui/icons-material/Replay';
import AtomicSpinner from 'atomic-spinner';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {useNavigate} from "react-router-dom"

import SpeechToText from "./SpeechToText";
import ClearIcon from '@mui/icons-material/Clear';

import { Button } from '@mui/material';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import {submitCustomEvent} from "../helper/customevents";
import TableChartIcon from '@mui/icons-material/TableChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Snackbar from '@mui/material/Snackbar';
import ScaleLoader from "react-spinners/ScaleLoader"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BasicModal from '../pages/BasicModal';
import { TypeAnimation } from "react-type-animation";

function AskFollowUp({ refToScrollableDiv, showQuestions = false}) {

  const [{followup, conversationHistory, loading, streaming, nextQuestion, nextQuestionLoading, userCred, entity, app_category}, dispatch] = useStateValue();
  const [input, setInput] = useState(followup);  
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [automatic, setAutomatic] = useState(false);
  const [open, setOpen] = useState(automatic);
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("")


  const entityMap = {
    "ria":"Ria",
    "sunny":"Sunny",
    "preeti":"Preeti",
    "default":"Guruji",
    "romila":"Romila",
    "raj":"Raj",
    "vikramjeet":"Vikram",
    };


  useEffect(() => {
    let interval;
  
    if (automatic) {
      // Show Snackbar
      setOpen(true);
  
      // Hide Snackbar after 5 seconds
      setTimeout(() => {
        setOpen(false);
      }, 5000);
  
      // Immediately dispatch the action when automatic is toggled on
      console.log("Next question called");
      dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
  
      // Set interval to dispatch the action every 15 seconds
      interval = setInterval(() => {
        console.log("Next question called");
        dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
      }, 15000);
    }
  
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [automatic]);
  

  const askfollowup = (e) => {
  
  

    if(loading || streaming)
      return;
    if(!!e)
      e.preventDefault();
    if(input === "")
      return;
    
      if(inputRef.current){
        inputRef.current.blur();//dismiss keyboard
      }
    
    
    // if(!!refToScrollableDiv && !!refToScrollableDiv.current){
    //   refToScrollableDiv.current.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   })
    // }
  
    //updateState();
    //console.log("askfollowup!!")
    dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: input });
    window.gtag('event', entity);
    
    setInput("");
  //   window.gtag('event', 'Refine_Input', {
  //     event_category: 'Refine_Input',
  //     event_label: input, 
  // });
  console.log(`Calling Google Analytics custom event: Refine input with input: ${input}`);
  submitCustomEvent('Refine_Input', input);
  }

   const isStart = () => conversationHistory.filter(u=>u.role == "user").length == 0;
  // const isStart = () => conversationHistory.length == 0;

  // useEffect(()=>{
  //   if(!!nextQuestion && nextQuestion.length>0) setInput(nextQuestion)
  // },[nextQuestion])

  

  return (
    <div>
        <Snackbar
    open={open}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    autoHideDuration={5000}
    onClose={() => setOpen(false)}
    message="Automatic mode enabled, press Stop Automatic to stop"
  />
      
{/*       
      <div style={{margin:"auto"}}>
        {nextQuestion!="" && <Button className='auto__gen__button' onClick={(_)=> {
          dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
          window.gtag('event', 'auto_gen', {
            event_category: 'auto_gen',
            event_label: nextQuestion, 
        });
        }}>{nextQuestion}</Button>} 
      </div> */}
      
    {/** style={{bottom:"5px", position:"fixed", padding:"2.5%"}}  height:"250px", padding:"2.5%",   height:"34vh",*/}
    {/** paddingLeft:"3vw", paddingRight:"3vw",paddingBottom:"1vh", */}
    <form className='askfollowup' style={{position:"fixed", bottom:"10px", background:"white", zIndex:1000, textAlign:"center"}} onSubmit={askfollowup}>
    <div className='svg-container' style={{display:"flex", justifyContent:"center" }}>
        {nextQuestionLoading && showQuestions && <AtomicSpinner />}
        {/* {!nextQuestionLoading && <div style={{height:"20px"}} />} */}
        {/* <AtomicSpinner/> */}
      </div>
    <div style={{display:"flex", justifyContent:"center"}}>
        {nextQuestion!="" && showQuestions && <Button className='auto__gen__button' onClick={(_)=> {
          dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
          window.gtag('event', 'auto_gen', {
            event_category: 'auto_gen',
            event_label: nextQuestion, 
        });
        window.gtag('event', entity);
       
        }}>{nextQuestion}</Button>} 
      </div>
           {/** style={{display: (conversationHistory.filter(u => u.role === "user" || u.role === "assistant").length > 0) && !loading ? "" : "none"}} */}
        <div className='askfollowup__input'>
            {/* <SearchIcon className='askfollowup__icon' /> */}
            <input ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} placeholder={isListening ? transcript:`Message Astrologer ..`}/>
            { input.length == 0 && !isListening && <ArrowCircleRightIcon style={{opacity: 0.5, fontSize:"2rem"}} onClick={askfollowup}/>}
            { input.length > 0 && !isListening && <ArrowCircleRightIcon style={{opacity: 1, fontSize:"2rem"}} onClick={askfollowup}/>}
          
           
            {/* <MicIcon/> */}

            <div className='svg-container' style={{display:"flex", justifyContent:"center" }}>
            { isListening && <ScaleLoader height={"24px"} color="silver" />}
      </div>
            
        </div>
        
        <Button style={{display:"none"}} type='submit' onClick={askfollowup} />
        <div className='refine_button_group' style={{minWidth:"80vw !important", maxHeight:"14vh", display:"none"}}>
         {/* <div className='refine_button_row'>
           
              <Button className='refine_button' startIcon={<ArticleIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} window.gtag('event', entity); dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Add more detail" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "More Detail", 
              });}}>Detail</Button>


<Button className='refine_button' startIcon={<BorderClearIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} window.gtag('event', entity); dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Simplify it, rewrite by making it very simple" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Simplify", 
            } ); }}>Simple</Button>

           

                <SpeechToText 
                  setTranscript = {setTranscript}
                  trigger={(transcript) => {
                    setTranscript("");
                    setInput("");
                  //  console.log("transcript called")
                   dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: transcript });
                   window.gtag('event', entity);   
                  } }
                  setListening={setIsListening} />
        </div> */}

      
       
        <div className='refine_button_row'>
       
              {/* <Button className='refine_button' startIcon={<img src="/gem.png" width={"30px"} style={{ paddingRight: "2%" }} />} 
              onClick={(_)=>{
                window.gtag('event', 'Remedies');
                console.log("remedies event");
                navigate("/astroshop");
                
                }}>Remedies</Button> */}
                {/** <img src="/icons/business.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} /> */}
                 {/* <Button className='refine_button' startIcon={<img src="/magic-wand.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />} 
                 onClick={(_)=>{
                  window.gtag('event', 'Remedies');
                console.log("remedies event");
                navigate("/astroshop");
                 }}>Store</Button> */}
            {/* <Button className='refine_button' startIcon={<AirlineSeatFlatAngledIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite by making it a bit informal" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });}}>Make it Informal</Button> */}
            {/* <Button className='refine_button' startIcon={<FormatListBulletedIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Bulletize it" }); window.gtag('event', 'Refine_PA',  {
                  event_category: 'Refine_PA',
                  event_label: "Bulletize", 
              });}}>Bulletize</Button> */}
            {/* <Button className='refine_button' startIcon={<AirlineSeatFlatAngledIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Return to the user search links from google.com context" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });}}>Get Search Links</Button> */}
            
            {/* <Button style={{fontSize:"14px !important"}} className='refine_button' startIcon={<LanguageIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Answer in Hindi but use english characters" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "To Hindi", 
            });}}>Hindi</Button> */}

<Button className='refine_button' startIcon={<ArticleIcon sx={{fontSize:"25px !important"}} />} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} window.gtag('event', entity); dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Add more detail" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "More Detail", 
              });}}>Detail</Button>

            <Button className='refine_button' startIcon={<CalendarMonthIcon sx={{fontSize:"25px !important"}}/>} onClick={ (_) => {          
            dispatch({type:actionTypes.RESET_STATE}); 
            navigate("/edit"); 
          }}>Edit</Button>
          {/* <BasicModal dispatch={dispatch} actionTypes={actionTypes}/> */}

{/* <Button 
            className='refine_button' 
            startIcon={<img src="/icons/magic.png" width={"30px"} style={{ paddingRight: "2%" }} />} 
            onClick={(e) => {
              setAutomatic(prevAutomatic => !prevAutomatic);
            }}
          >
            {automatic ? 'Stop Automatic' : 'Start Automatic'}
            
          </Button> */}

            
{/* <Button style={{fontSize:"14px !important"}} className='refine_button' startIcon={<LanguageIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "केवल हिंदी भाषा में उत्तर दें" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "To Hindi", 
            });}}>हिंदी</Button> */}

{/* {true && <Button className='refine_button' startIcon={<img src="https://astrosky.blob.core.windows.net/astrosky/report.png" alt="Constellation" style={{marginLeft:"1px",  width:"25px", height:"25px", textDecoration: 'none' }} />} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} window.gtag('event', entity); navigate("/reports"); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "Report", 
              });}}></Button>} */}



            <Button className='refine_button' startIcon={<img src="/icons/horoscope.png" width={"25px"} style={{ paddingRight: "2%" }} />} onClick={ (_) => {
              window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Back", 
            });             
          //  dispatch({type:actionTypes.RESET_STATE}); 
            navigate("/discover"); 
           // dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []});
          }}>New Chat</Button>
          
          

          {/* <Button className='refine_button' startIcon={<ClearIcon/>} onClick={ (_) => {
                         
           setInput("");
          }}>Clear</Button> */}
         




{/* <Button className='refine_button' startIcon={<img src="/icons/solar-system.png" width={"30px"} style={{ paddingRight: "2%" }} />} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "generate vedic birthchart table" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "Chart", 
              });}}>Chart</Button> */}
{/** generate vedic birthchart table */}
{/* <Button className='refine_button' startIcon={<img src="/icons/sun.png" width={"30px"} style={{ paddingRight: "2%" }} />} onClick={ (_) => {
              window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Try Pro", 
            });           
            
            if(!!userCred){
              navigate("/profile");
            } else{
              navigate("/loginpage");
            }

          }}>Try Pro for Free</Button> */}

         {/* {!!conversationHistory && conversationHistory.length>20 && <Button className='refine_button' startIcon={<img src="/feedback_power.png" width={"30px"} style={{ paddingRight: "2%" }} />} onClick={ (_) => {
              window.gtag('event', 'feedback');         
            //dispatch({type:actionTypes.RESET_STATE}); 
            navigate("/feedback"); 
            //dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []});
          }}>Feedback</Button>} */}
             {/* <Button className='refine_button' startIcon={<LanguageIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite in Hindi" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "To Hindi", 
            });}}>Rewrite in Hindi</Button> */}
          </div>
          {/* <div className='refine_button_row'>
          {true && <Button className='refine_button' startIcon={<img src="https://astrosky.blob.core.windows.net/astrosky/report.png" alt="Constellation" style={{marginLeft:"8px",  width:"25px", height:"25px", textDecoration: 'none' }} />} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} window.gtag('event', entity); navigate("/reports"); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "Report", 
              });}}>Reports</Button>}
          </div> */}
        </div>
      
        
    </form>

    {/* {nextQuestion!="" && 
      
      //last
      (_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.UPDATE_CONVERSATION_HISTORY, enrichedConversationHistory: [] }); dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "" }); dispatch({type:actionTypes.SET_LOADING, loading: false });

      <div>
        <div>Related</div>
      <Button onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: {nextQuestion} }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Simplify", 
            } ); }}>{nextQuestion}</Button></div> } */}

    </div>
  )
}

export default AskFollowUp
import { signInWithCustomToken } from 'firebase/auth';
import { actionTypes } from '../statemanagement/reducer';
import { setDoc, serverTimestamp, doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

const uatUrl = "https://astroservice-uat.azurewebsites.net/";

const getFirebaseToken = async (uid) => {
    console.log(`Attempting to get firebase token`);
    const firebaseToken = await fetch(`${uatUrl}/token`, {
        body: JSON.stringify({"uid": uid }),
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }

    });
    return firebaseToken
}

const authenticateUser = async (phoneNumber) => {
    const u = await getFirebaseToken(phoneNumber);
     const data = await u.json();
     const customToken = data.firebaseToken;
     return signInWithCustomToken(auth, customToken).then((usercredential) => {
         // console.log(`Firebase user is`);
         //   console.log(usercredential);
         const uid = usercredential.user.uid;
         //const accessToken = usercredential.user.accessToken;
         // dispatch({type: actionTypes.SET_USER_CRED, userCred:{uid}});
         return { uid };
     });
 }

export const updateUserTierFunc = async (uid) =>{
    const userRef = doc(db, "users", uid);
    console.log(`UID: ${uid}`);
    await updateDoc(userRef, {
        tier: "premium"
    })

}

export const upgradeToPremium = async (uid) => {
    console.log("calling upgrade to premium");
    const userRef = doc(db, "users", uid); //pointing
    const userSnap = await getDoc(userRef);
    if(userSnap.exists()){
        console.log("updating user doc");
        await updateDoc(userRef, {
            tier: "premium"
        })
    } 

}

export const authenticateWithFirebasePlusUpdateFirestore = async (phoneNumber="", phoneHash) => {
    
    const userCredObject = await authenticateUser(phoneHash);
    
     const userRef = doc(db, "users", userCredObject.uid);

     const userSnap = await getDoc(userRef);

     if(userSnap.exists()) {
        await updateDoc(userRef,{
            logintime: serverTimestamp()
        })

     } else {
        if(phoneNumber != ""){
            await setDoc(userRef, {
                uid: userCredObject.uid,
                phone: phoneNumber,
                logintime: serverTimestamp(),
                tier:"free"
            });
        }
        
     }

     return userCredObject;

     

    
 }
import React from 'react';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';

const Shop = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="container" style={{padding:"3.9%"}}>
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>AstroSky Shop</h1>
                            <br />
                            
                            <img src='/astrologer.webp' width={"100%"}/>
                            <a href='https://amzn.to/49rDy9A'>Buy Now</a>
                         
                            
                      
                            <br />
                           
                            <br />
                        </div>
                        <br />

                    </div>
                    <br />

                </div>

            </div>
            <Footer/>
        </div>


    );
};

export default Shop;

import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import AtomicSpinner from "atomic-spinner"; 
import { Helmet } from "react-helmet";
const StaticPage = ({ apiUrl }) => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${apiUrl}?populate=*`);
        
        const data = response.data.data;
        if (data && data.attributes) {
          setContent(data.attributes); 
        } else {
          setError('Content not found');
        }
      } catch (error) {
        setError('Error fetching content');
        console.error('Error fetching content:', error); 
      } finally {
        setLoading(false);
      }
    };
    
    fetchContent();
  }, [apiUrl]);
  if (loading) {
    return (
      <div className='spinner'>
        <AtomicSpinner />
      </div>
    );
  }
  if (error) {
    return <div className='error'>{error}</div>;
  }
  if (!content) {
    return <div className='error'>Content not found</div>;
  }
  const { description, SEO } = content;
  const { metaTitle, metaDescription, primaryKeywords,H1, H2, secondaryKeywords, canonicalURL } = SEO[0] || {};
  const renderDescription = (desc) => {
    return desc.map((item, index) => {
      switch (item.type) {
        case 'heading':
          return React.createElement(`h${item.level}`, { key: index }, item.children.map(child => child.text).join(''));
        case 'paragraph':
          return <p key={index}>{item.children.map(child => child.text).join('')}</p>;
        default:
          return null;
      }
    });
  };

  return (
    <div className="content-detail">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription || ''} />
        <meta 
          name="keywords" 
          content={`${primaryKeywords || ''}${primaryKeywords && secondaryKeywords ? ', ' : ''}${secondaryKeywords || ''}`} 
        />        
        <meta name="robots" content={SEO?.metaRobots || 'index, follow'} />
        <meta name="viewport" content={SEO?.metaViewport || 'width=device-width, initial-scale=1'} />
        <link rel="canonical" href={SEO?.canonicalURL}/>
      </Helmet>
      <div className="content-description">
        {renderDescription(description)}
      </div>
    </div>
  );
};

export default StaticPage;

import AppFooter from "./AppFooter";
import Header from "./Header";
import SKU from "./SKU";
import { TypeAnimation } from "react-type-animation";

//the items we want to sell
const AstroShop = () => {

    return (<div>
        <div className="container" style={{padding:"3.9%"}}>
            <Header showLogin={false}/>
            <div style={{margin:"2%"}}>
                <br/>
               
                {/* <div style={{textAlign:"center", fontSize:"20px", fontWeight:"500"}}>Vedic Remedies</div> <br/> */}
                <TypeAnimation sequence={[
                            `वृत्रस्य बोनेह सुजातमुग्रं वैराणामन्तं परिपान्तु रत्नम्।
                            त्रयस्त्रिंशद्देवजातं च रत्नमग्नेर्जातं परिपान्त्वस्मान्॥`,
                            100,
                            `Harness the ancient power of divine gemstones as revealed in the Veda's and Purana's`

                        ]} wrapper="span" speed={50} style={{ fontSize: "1em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                <br/>

              
               
              
              
               

                <br/>
                <SKU img={"/tiger.webp"} name={"Tiger Eye Pendant"} link={"https://amzn.to/3UvKNsT"} benefits={"Enhance your Personal Power, Bring Good Luck, Prosperity and Success"}/> <br/>
                
               
                <SKU img={"/locket_.jpg"} name={"Tiger Eye Pendant Locket"} link={"https://amzn.to/4d6Nl8p"} benefits={"Brings Good Luck, Prosperity, Wealth And Success"}/>
                <br/>
                
                <SKU img={"/pukhraj.jpg"} scripture={"Brihat Samhita: पीतमणिः शुभो गुरौ, जीवाय धनधान्यदः। "}  name={"Yellow Sapphire Pukhraj Gemstone"} link={"https://amzn.to/3w80tcw"} benefits={"Brings Prosperity, Wealth And Success. Enhances the positive effects of Jupiter, which include wisdom, luck, health, and spiritual growth"}/>
                 <br/>
                
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} showBack={true}/>
        </div> 
    </div>);
}

export default AstroShop;
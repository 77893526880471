import React from 'react';
import WebFooter from "./webFooter";
import WebHeader from "./webHeader";
import './webLayout.css';
const WebLayout = ({ children }) => {
  return (
    <div className="web-layout">
      <WebHeader />
      <main className="main-content">
        {children}
      </main>
      <WebFooter />
    </div>
  );
};
export default WebLayout;

import Card from '@mui/material/Card';
import { Button, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CoralSignificance(benefits, scripture) {
  console.log(`Scripture: ${scripture}`)
  if(!!scripture && scripture != ""){
    return (
      <div>
        <ul>
        <li><strong>{scripture}</strong></li>
          <li><strong>Benefits: </strong>{benefits}</li>
        </ul>
      </div>
    );
  }else{
    return (
      <div>
        <ul>
          <li><strong>Benefits: </strong>{benefits}</li>
        </ul>
      </div>
    );
  }
    
  }

const SKU = ({name, img, link, benefits, scripture}) => {

    const navigate = useNavigate();

    return (<Card style={{boxShadow:"none"}}>
    <CardMedia sx={{height: 300}}
               image={img}
               title="" 
               />
    <CardContent>
        <Typography gutterBottom variant="h5" component={"div"}>{name}</Typography>
        <Typography variant="body2" color={"text.secondary"}>
          {CoralSignificance(benefits, scripture)}
        </Typography>
    </CardContent>
    <CardActions>
        <div style={{margin:"auto"}}>
            <Button style={{width:"88vw", height:"50px", background:"rgb(255, 240, 0)", color:"black", borderRadius:"8px", boxShadow:"0 2px 5px 0 rgba(213,217,217,.5)", textTransform:"none", fontSize:"1.2rem"}} size="small" onClick={()=>{
                //navigate("/order")
                window.gtag('event', 'Goto_Amazon');
                window.location.href = link;
            }}>Buy From Amazon</Button>
        </div>
       
    </CardActions>
</Card>);

}

export default SKU;
import axios from 'axios';

const API_URL = 'https://steadfast-cows-9445c3a50f.strapiapp.com';

export const getBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/blogs?populate=*`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const getContent = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/contents?populate=*`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

import React, { useState } from "react";
import { auth } from "../config/firebase";
import { Button, Snackbar } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./PhoneComponent.css"
import TextField from '@mui/material/TextField';
import "../styles/general.css";
import { db } from "../config/firebase";
import { serverTimestamp, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { generateReferralCode, verifyPhoneNumberPattern } from "./LoginHelper";
import AtomicSpinner from 'atomic-spinner';

const LoginPage = () => {

    const [_, dispatch] = useStateValue();
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [otp, setOtp] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);

    const [phone, setPhone] = useState("");

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const setRecaptchaVerifierToGlobalWindowObject = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptchaContainer', {
            'size': 'invisible',
            'callback': (response) => {
            }
        });
    }
    
    const handlePhoneNumberDataEntry = (number) => {
        setLoading(true);

        if (!verifyPhoneNumberPattern(number)) { 
            setErrorMessage("Enter a Valid Phone Number");
            setShowError(true);
            setLoading(false);
            return;
        }
        else{
            if(!window.recaptchaVerifier){
                setRecaptchaVerifierToGlobalWindowObject();
            }
                
            const appVerifier = window.recaptchaVerifier;

            //Create_OTP
            window.gtag('event', 'Create_OTP');
    
            signInWithPhoneNumber(auth, number, appVerifier).then((confirmationResult) => {
                setConfirmationResult(confirmationResult); //non null variable signifies successful otp flow
            }).catch((error) => {
                setErrorMessage("Failed to generate OTP, Try again");
                console.error("Failed to generate OTP, Try again", error);
            }).finally(()=>{
                setLoading(false);
            })
        }

       

    }

    return (
        <div>
            <div className="container">
                {showError && <Snackbar message={errorMessage} open={showError} autoHideDuration={5000} onClose={() => setShowError(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }} />}
                <Header />
              <div style={{padding:"0.75em"}}>
                <div style={{ marginTop: "2%" }}>
                    <b>Enter Your Phone Number below</b><br />
                    <br />
                    <PhoneInput defaultCountry="in" value={phone} onChange={(phone) => setPhone(phone)} />
                    <br />
                    <Button className="astroskyButtonStyle" onClick={(e) => handlePhoneNumberDataEntry(phone)}>Get OTP</Button>
                    <br /> <br />
                    { loading && <div style={{textAlign:"center"}}><AtomicSpinner style={{ width: '250px', height: '250px' }} /></div>}
                    {!loading && !!confirmationResult && (<><b>OTP Sent to {phone}, Enter below:</b><br />  <br /></>)}

                    {!loading && !!confirmationResult && (<><TextField onChange={(otp) => setOtp(otp.target.value)} label="Enter OTP" type="number" />
                        <br /> <br />


                        <Button style={{ width: "88vw", height: "50px", background: "rgb(255, 240, 0)", color: "black", borderRadius: "26px", boxShadow: "0 2px 5px 0 rgba(213,217,217,.5)", textTransform: "none", fontSize: "1.2rem" }}
                            onClick={async (e) => {
                                setLoading(true);
                                if (!!confirmationResult && !!otp && otp.length>0) {

                                    try {
                                        const userCredentials = await confirmationResult.confirm(otp);
                                        //userCredentials pulled from Firebase Auth
                                        // console.log(`User Credential object returned from Firebase Auth is:`);
                                        // console.log(userCredentials);

                                        //user phone has been verified


                                        //start listening to user updates from db    
                                        dispatch({ type: actionTypes.SET_UID, uid: userCredentials.user.uid }); //store uid from userCredentials in state
                                        //push this in localstorage?
                                        localStorage.setItem("uid", userCredentials.user.uid);

                                        //reference to user in db
                                        const userRef = doc(db, "users", userCredentials.user.uid); //reference to user document
                                        //const tierRef = doc(db, "tiers", userCredentials.user.uid);

                                        // const userSnapshot = await getDoc(userRef); //get user document
                                        // const tierSnapshot = await getDoc(tierRef); //get tier corresponding to user

                                        //set in db
                                        await setDoc(userRef, {
                                            uid: userCredentials.user.uid,
                                            phone: userCredentials.user.phoneNumber,
                                            accessToken: userCredentials.user.accessToken,
                                            logintime: serverTimestamp()
                                        }); 

                                        window.gtag('event', 'OTP_Verified');

                                        // if (!userSnapshot.exists() && !tierSnapshot.exists()) { //user document does not exist
                                        //     // await setDoc(userRef, {
                                        //     //     uid: userCredentials.user.uid,
                                        //     //     phone: userCredentials.user.phoneNumber,
                                        //     //     accessToken: userCredentials.user.accessToken,
                                        //     //     logintime: serverTimestamp()
                                        //     // }); 
                                        //     // await setDoc(tierRef,{
                                        //     //     uid: userCredentials.user.uid,
                                        //     //     tier: "Free",
                                        //     //     premiumAnswerCount:0
                                        //     // })
                                        // }
                                        // else {
                                        //     console.log("exists");
                                        //     await updateDoc(userRef, {
                                        //         logintime: serverTimestamp()
                                        //     })
                                        // }

                                        navigate("/");
                                    } catch (e) {
                                        setLoading(false);
                                        setErrorMessage("Incorrect OTP, OTP could not be verified, Try Again");
                                        console.log("Incorrect OTP, OTP could not be verified, Try Again", e)
                                        return;
                                    }


                                }
                            }}>Verify OTP</Button> <br /> <br />
                        <p><i>* OTP Login is Powered by Google Firebase</i></p> <br />
                    </>)}


                    {/* <div id="recaptchaContainer"></div> */}
                </div>
                </div>
                <div id="recaptchaContainer"></div> 
            </div>
            <Footer />
        </div>);
}

export default LoginPage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DropdownMenu from './dropdownMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './webHeader.css';

const WebHeader = () => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuState, setMenuState] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1038);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1038);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuOpen = (event, menu) => {
        setMenuState({ ...menuState, [menu]: event.currentTarget });
    };

        const menuItems = [
        { text: 'Home', href: '/' },
        {
            text: 'Kundli', subMenu: [
                { text: 'Free Kundli', href: '/free-kundli' },
                { text: 'Marriage Kundli Matching', href: '/marriage-kundli-matching' },
                { text: 'How to read Kundli', href: '/how-to-read-kundli' },
            ]
        },
        {
            text: 'Horoscope', subMenu: [
                { text: 'Today Horoscope', href: '/today-horoscope' },
                { text: 'Tomorrow Horoscope', href: '/tomorrow-horoscope' },
                { text: 'Weekly Horoscope', href: '/weekly-horoscope' },
                { text: 'Monthly Horoscope', href: '/monthly-horoscope' },
                { text: 'Yearly Horoscope', href: '/yearly-horoscope' },
                { text: 'Couple Horoscope', href: '/couple-horoscope' },
                { text: 'Marriage Horoscope', href: '/marriage-horoscope' },
                { text: 'Career & Business Horoscope', href: '/career-business-horoscope' },
                { text: 'Wealth & Fortune Horoscope', href: '/wealth-fortune-horoscope' }
            ]
        },
        {
            text: 'Calculators', subMenu: [
                { text: 'Love Calculator', href: '/love-calculator' },
                { text: 'Age Calculator', href: '/age-calculator' },
                { text: 'Career Calculator', href: '/career-calculator' },
                { text: 'Lucky Dates Calculator', href: '/lucky-dates-calculator' },
                { text: 'Lucky Rudraksha Calculator', href: '/lucky-rudraksha-calculator' },
                { text: 'Baby Name Generator', href: '/baby-name-calculator' },
            ]
        },
        {
            text: 'Predictions', subMenu: [
                { text: 'Daily Predictions', href: '/daily-prediction' },
                { text: 'Weekly Predictions', href: '/weekly-prediction' },
                { text: 'Numerology Predictions', href: '/numerology-prediction' },
                { text: 'Gemstone Recommendation', href: '/gemstones-predictions' },
                { text: 'Marriage Prediction by DoB', href: '/marriage-prediction-by-date-of-birth' },
                { text: 'Wealth Prediction by DoB', href: '/wealth-prediction-by-date-of-birth' },
                { text: 'Health Prediction by DoB', href: '/health-prediction-by-date-of-birth' },
            ]
        },
        {
            text: 'Transits', subMenu: [
                { text: 'Rahu Ketu Transits', href: '/rahu-ketu-transits' },
                { text: 'Saturn Transits', href: '/saturn-transits' },
                { text: 'Jupiter Transits', href: '/jupiter-transits' },
            ]
        },
        // {
        //     text: 'AstroSky Store', subMenu: [
        //         { text: 'Online Puja Booking', href: '/online-puja-booking' },
        //         { text: 'Gemstones online', href: '/gemstones-online' },
        //         { text: 'Rudraksha online', href: '/rudraksha-online' },
        //         { text: 'Kawach', href: 'kawach-online' },
        //         { text: 'Fengshue', href: '/fengshue-online' },
        //         { text: 'Reiki Healing', href: '/reiki-healing-online' },
        //         { text: 'Bracelets & Pendants', href: '/bracelets-pendants-online' },
        //         { text: 'Palmistry', href: '/palmistry-online' },
        //         { text: 'Name Correction', href: '/name-correction-numerology' },
        //     ]
        // },
        {
            text: 'Misc', subMenu: [
                { text: 'Blogs', href: '/blogs' },
                // { text: 'Tarot', href: '/tarot' },
                // { text: 'Celebrity Horoscopes', href: '/celebrity-horoscopes' },
                // { text: 'Hindu Festivals 2024', href: '/hindu-festivals' },
                // { text: 'Customer Reviews', href: '/customer-reviews' },
            ]
        },
    ];

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleNavigate = (href) => {
        navigate(href);
        setDrawerOpen(false);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div className="web-header">
            <AppBar position="static" className="top-header">
                <Toolbar className="header-content">
                    <div className="logo">
                        <div className="logo-container">
                            <img
                                src='../../../icons/horoscope.png'
                                alt="Logo"
                                className="logo-image"
                                onClick={() => { navigate("/") }}
                            />
                            <div className="logo-text-container" onClick={() => { navigate("/") }}>
                                <Typography variant="h6" className="logo-text">AstroSky</Typography>
                                <Typography variant="caption" className="logo-subtext">Powered by AI</Typography>
                            </div>
                        </div>
                        <div className="motto">
                            <h5><i>Quick, Modern & Accurate!</i></h5>
                        </div>
                    </div>

                    {!isMobile && (
                        <div className="header-actions">
                            <Button variant="contained" className="action-button" onClick={() => { navigate("/") }}>Chat with Astrologer</Button>
                        </div>
                    )}
                    
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' } }}
                            onClick={handleToggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            {!isMobile && (
                <div className="bottom-header">
                    <nav className="nav-links">
                        {menuItems.map((item) => {
                            if (item.subMenu) {
                                return (
                                    <div key={item.text}>
                                        <DropdownMenu key={item.text} title={item.text} items={item.subMenu} />
                                    </div>
                                );
                            } else {
                                return (
                                    <a key={item.text} href={item.href}>{item.text}</a>
                                );
                            }
                        })}
                    </nav>
                </div>
            )}

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <div className="drawer-header">
                        <img src='../../../icons/horoscope.png' alt="Logo" className="drawer-logo" />
                        <div className="logo-text-container">
                            <Typography variant="h6" className="logo-text">AstroSky</Typography>
                            <Typography variant="caption" className="logo-subtext">Powered by AI</Typography>
                        </div>
                    </div>
                    <Divider />
                    <List>
                        {menuItems.map((item) => (
                            item.subMenu ? (
                                <div key={item.text}>
                                    <ListItem button onClick={(event) => { handleMenuOpen(event, item.text) }}>
                                        <ListItemText primary={item.text} />
                                        <ArrowDropDownIcon />
                                    </ListItem>
                                    <List component="div" disablePadding className="drawer-submenu">
                                        {item.subMenu.map((subItem) => (
                                            <ListItem button key={subItem.text} onClick={() => handleNavigate(subItem.href)}>
                                                <ListItemText primary={subItem.text} style={{ paddingLeft: '30px' }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            ) : (
                                <ListItem button key={item.text} onClick={() => handleNavigate(item.href)}>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            )
                        ))}
                    </List>
                </div>
            </Drawer>
        </div>
    );
};

export default WebHeader;


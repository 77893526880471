import { useEffect } from "react";

import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { fetchHoroscopeData } from "../helper/fetchHelpers/fetchHelpers";

/**
 * Pull from cache and update state
 * based on updated state, fetch horoscope
 */
const UseBirthDetails = () => {

    const [{horoscope, dob, tob, geo}, dispatch] = useStateValue();

    useEffect(()=>{
        
        //pull location details from cache
        const cityCache = localStorage.getItem("city");
        const latCache = localStorage.getItem("lat");
        const lonCache = localStorage.getItem("lon");

     //   console.log(`City from cache is: ${cityCache}`);
        

        
        //update state with location details
        if(!!cityCache && !!latCache && !!lonCache){
            dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:Number(latCache), lon:Number(lonCache)}});
            dispatch({type: actionTypes.SET_USER_CITY, city: cityCache});   
        }
    
        const dob = localStorage.getItem("dob");
        const tob = localStorage.getItem("tob");
        if(!!dob && !!tob){
            dispatch({type: actionTypes.SET_USER_DOB, dob: dob });
            dispatch({type: actionTypes.SET_USER_TOB, tob: tob });
        }

        //re-fetch horoscope
        // fetchHoroscopeData(dob, tob, Number(latCache), Number(lonCache), horoscope).then((h_d)=>{
        //     dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_d, dob:dob, tob:tob, lat:Number(latCache), lon:Number(lonCache)}});
        // })
        
    },[])

    useEffect(()=>{
        // console.log(`GEO Set to state`);
        // console.log(JSON.stringify(geo));

        // console.log(`DOB set to state`);
        // console.log(dob);

        // console.log(`TOB set to state`);
        // console.log(tob);

        if(!!geo && !!dob && !!tob){ //horoscope is a function of 
           // console.log("Attempt to Fetch Horoscope");

            fetchHoroscopeData(dob, tob, geo.lat, geo.lon, horoscope).then((h_d)=>{
                dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_d, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
            })

        }

    },[geo, dob, tob]) //all of them have been set 
}

export default UseBirthDetails;
import React, { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import useAIService from "../helpers/useAIService";
import Search from '../components/Search';
import "./Results.css";
import "./BasicChat.css"
import Refine from "../components/AskFollowUp";
import { useNavigate } from "react-router-dom"
import AtomicSpinner from 'atomic-spinner';
import "./BasicChat.css"
import RightSidebar from '../components/RightSidebar';
import { getImageSrc } from './Mode_Util/util_mode';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function AIReport() {

  const [{ term, followup, conversationHistory, loading, streaming, nextQuestion, chatMode, user, subscription, mode, entity }, dispatch] = useStateValue();
  const refToScrollableDiv = useRef(null);
  useAIService(term, followup, refToScrollableDiv, user, subscription);


  const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));


  const [autoScroll, setAutoScroll] = useState(true);

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {

    if (conversationHistory.length < 2) return;

    if (!refToScrollableDiv.current) return;

    if (autoScroll && streaming) {
      const { scrollHeight } = refToScrollableDiv.current;
      refToScrollableDiv.current.scrollTo(0, scrollHeight);
    }
    if (followup.length > 0) {
      const { scrollHeight } = refToScrollableDiv.current;
      refToScrollableDiv.current.scrollTo(0, scrollHeight);
    }

  }, [conversationHistory, autoScroll, followup]);

  useEffect(() => {
    if (term.length > 0 || followup.length > 0) setAutoScroll(true);
  }, [term, followup])

  useEffect(() => {
    const scrollContainer = refToScrollableDiv.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("mousedown", () => setAutoScroll(false));
      scrollContainer.addEventListener("touchstart", () => setAutoScroll(false));
      scrollContainer.addEventListener("click", () => setAutoScroll(false));
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("mousedown", () => setAutoScroll(false));
        scrollContainer.removeEventListener("touchstart", () => setAutoScroll(false));
        scrollContainer.removeEventListener("click", () => setAutoScroll(false));
      }
    }

  }, [])

  return (

    <div className='resultsPage'>

      {
        <div className='resultsPage__header'>
          <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }} className='resultsPage__header__prompt__entry'>
            <img src="/icons/horoscope.png" width={"38px"} style={{ paddingRight: "2%" }} onClick={(_) => navigate("/")} />

            <RightSidebar />
          </div>

        </div>
      }

      <div className='resultsPage__body' ref={refToScrollableDiv}>

        <div className='resultsPage__ai__response'>

          {(

            displayConversationHistory
              .filter(u => u.role === "assistant" || u.role === "user")
              .map((u, index) => {
                if (u.content == "AstroSky") return;
                if (u.role == "user") {
                  return (
                    <>

                      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
                        {/* <div
                          style={{
                            background: "whitesmoke",
                            whiteSpace: "pre-line",
                            padding: "3%",
                            borderRadius: "10px",
                            marginRight: "10px"
                          }}
                          dangerouslySetInnerHTML={{ __html: u.content }}
                        ></div> */}
                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                          <AccountCircleIcon style={{ fontSize: "45px" }} />
                        </div> */}
                      </div>
                      <br />
                    </>

                  )
                }





                return (<div key={index} className='ai__display'>
                  <div className='ai__display__assistant'>
                    {
                      <>
                        <br />

                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* <img
                              src={getImageSrc(entity)}
                              width={"45px"}
                              style={{ borderRadius: "10px" }}
                              alt="entity"
                            /> */}
                          </div>
                          <div
                            style={{ background: "whitesmoke", whiteSpace: "pre-line", padding: "3%", marginLeft: "10px", borderRadius: "10px" }}
                            dangerouslySetInnerHTML={{ __html: `<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #FFF; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                            <div style="text-align: center; font-size: 24px; font-weight: bold; margin-bottom: 20px;">Moon in Mula Nakshatra</div>
                            <div style="text-align: center; margin-bottom: 20px;">
                                <img src="https://astrosky.blob.core.windows.net/astrosky/chitra.jpeg" alt="Mula Nakshatra" style="max-width: 100%; height: auto; border-radius: 8px;">
                            </div>
                            <div style="text-align: center; font-size: 16px;">
                                <h2 style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">Career Overview</h2>
                                <p style="margin: 10px 0; line-height: 1.5;">
                                    With the Moon in Mula Pada 3, you are known for your analytical mind and intense focus.
                                    <span style="display: inline-block; filter: blur(3px); color: rgba(0, 0, 0, 0.2);">
                                        You have a deep desire to dig into the core of any subject, often leading you to careers that require investigation, research, or problem-solving. Your natural curiosity and ability to uncover hidden truths make you excellent in roles where critical thinking is essential. However, you may face challenges in maintaining consistency and could experience significant career changes, especially in your early professional life.
                                    </span>
                                </p>
                                <p style="text-align: center; font-size: 14px; color: #888; margin-top: 20px;">
                                    <em>Unlock the full content by paying.</em>
                                </p>
                            </div>

                            <div style="text-align: center; margin-bottom: 20px;">
                              <img src="https://astrosky.blob.core.windows.net/astrosky/chitra.jpeg" alt="Mula Nakshatra" style="max-width: 100%; height: auto; border-radius: 8px;">
                            </div>
                            <div style="text-align: center; font-size: 16px;">
                                <h2 style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">Career Overview</h2>
                                <p style="margin: 10px 0; line-height: 1.5;">
                                    With the Moon in Mula Pada 3, you are known for your analytical mind and intense focus.
                                    <span style="display: inline-block; filter: blur(3px); color: rgba(0, 0, 0, 0.2);">
                                        You have a deep desire to dig into the core of any subject, often leading you to careers that require investigation, research, or problem-solving. Your natural curiosity and ability to uncover hidden truths make you excellent in roles where critical thinking is essential. However, you may face challenges in maintaining consistency and could experience significant career changes, especially in your early professional life.
                                    </span>
                                </p>
                                <p style="text-align: center; font-size: 14px; color: #888; margin-top: 20px;">
                                    <em>Unlock the full content by paying.</em>
                                </p>
                            </div>

                            <div style="text-align: center; margin-bottom: 20px;">
                            <img src="https://astrosky.blob.core.windows.net/astrosky/chitra.jpeg" alt="Mula Nakshatra" style="max-width: 100%; height: auto; border-radius: 8px;">
                          </div>
                          <div style="text-align: center; font-size: 16px;">
                              <h2 style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">Career Overview</h2>
                              <p style="margin: 10px 0; line-height: 1.5;">
                                  With the Moon in Mula Pada 3, you are known for your analytical mind and intense focus.
                                  <span style="display: inline-block; filter: blur(3px); color: rgba(0, 0, 0, 0.2);">
                                      You have a deep desire to dig into the core of any subject, often leading you to careers that require investigation, research, or problem-solving. Your natural curiosity and ability to uncover hidden truths make you excellent in roles where critical thinking is essential. However, you may face challenges in maintaining consistency and could experience significant career changes, especially in your early professional life.
                                  </span>
                              </p>
                              <p style="text-align: center; font-size: 14px; color: #888; margin-top: 20px;">
                                  <em>Unlock the full content by paying.</em>
                              </p>
                          </div>

                          <div style="text-align: center; margin-bottom: 20px;">
                          <img src="https://astrosky.blob.core.windows.net/astrosky/chitra.jpeg" alt="Mula Nakshatra" style="max-width: 100%; height: auto; border-radius: 8px;">
                        </div>
                        <div style="text-align: center; font-size: 16px;">
                            <h2 style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">Career Overview</h2>
                            <p style="margin: 10px 0; line-height: 1.5;">
                                With the Moon in Mula Pada 3, you are known for your analytical mind and intense focus.
                                <span style="display: inline-block; filter: blur(3px); color: rgba(0, 0, 0, 0.2);">
                                    You have a deep desire to dig into the core of any subject, often leading you to careers that require investigation, research, or problem-solving. Your natural curiosity and ability to uncover hidden truths make you excellent in roles where critical thinking is essential. However, you may face challenges in maintaining consistency and could experience significant career changes, especially in your early professional life.
                                </span>
                            </p>
                            <p style="text-align: center; font-size: 14px; color: #888; margin-top: 20px;">
                                <em>Unlock the full content by paying.</em>
                            </p>
                        </div>

                        </div>`}}
                          ></div>
                        </div>

                        <br /> <br />
                      </>
                    }

                  </div>

                </div>

                )
              })
          )}
          {loading && <div className='ai__display'>
            <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AtomicSpinner style={{ width: '250px', height: '250px' }} />
            </div></div></div>
          }
        </div>
      </div>
      <div className='resultsPage__footer'>
        {/* <Refine refToScrollableDiv={refToScrollableDiv} showQuestions={true} /> */}
        
      </div>

    </div>
  );
}

export default AIReport;


import React from 'react';

const QuestionsDisplay = ({sampleQuestions}) => {
  const questionsArray = sampleQuestions.split(', ');

  return (
    <div style={styles.container}>
      {questionsArray.map((question, index) => (
        <p key={index} style={styles.question}>{question}</p>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding:"3px"
  },
  question: {
    fontSize: '1.1rem',
    fontWeight:"400",
    margin: '6px 0',
    color: "#616161",
    opacity: "1",
    fontFamily: "Poppins,sans-serif"
  },
};

export default QuestionsDisplay;


// import React from 'react';
// import { actionTypes } from '../statemanagement/reducer';

// const QuestionsDisplay = ({ sampleQuestions, entityCode, dispatch, navigate, horoscope}) => {
//   const questionsArray = sampleQuestions.split(', ');



//   return (
//     <div style={styles.outerContainer}>
//       <div style={styles.scrollContainer}>
//         <div style={styles.scrollContent}>
//           {questionsArray.map((question, index) => (
//             <button key={index} style={styles.questionButton} onClick={(_) => {

//               if (!horoscope) {
//                 navigate("/edit");
//                 return;
//               }

//               window.gtag('event', 'auto_gen', {
//                 event_category: 'auto_gen',
//                 event_label: question, 
//               });
//               window.gtag('event', entityCode);

//               if (entityCode !== "guruji") {
//                 dispatch({ type: actionTypes.SET_ENTITY, entity: entityCode });
//               }

//               dispatch({ type: actionTypes.SET_SEARCH_TERM, term: question });
//               dispatch({ type: actionTypes.SET_LOADING, loading: true });
//               navigate("/query");
//             }}>
//               {question}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   outerContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '100%',
//     overflow: 'hidden',
//     backgroundColor: '#ffffff'
//   },
//   scrollContainer: {
//     display: 'flex',
//     justifyContent: 'center', // Center content horizontally
//     alignItems: 'center', // Center content vertically
//     width: '100%',
//     scrollBehavior: 'smooth',
//   },
//   scrollContent: {
//     display: 'flex',
//     flexDirection: 'column', // Arrange buttons in a column
//     alignItems: 'center',
//   },
//   questionButton: {
//     fontSize: '1rem',
//     fontWeight: '400',
//     margin: '6px 0', // Add vertical margin between buttons
//     color: '#616161',
//     backgroundColor: '#f0f0f0',
//     border: 'none',
//     borderRadius: '10px',
//     padding: '10px 15px',
//     cursor: 'pointer',
//     fontFamily: 'Poppins, sans-serif',
//     opacity: '1',
//     transition: 'background-color 0.3s',
//     width: '100%', // Full width of the scrollContent div
//     maxWidth: '400px', // Optional max width for larger screens
//     textAlign: 'center', // Center text within the button
//   },
// };

// export default QuestionsDisplay;



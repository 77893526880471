import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Button, TextField, Box, Typography, Snackbar, Alert } from '@mui/material';

export const ContactUs = () => {
  const form = useRef();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const sendEmail = (e) => {
    console.log("Buy Order event");
    window.gtag('event', 'Buy_Order');
    e.preventDefault();
    emailjs.sendForm('service_hko0v25', 'template_1l85095', form.current, { publicKey: 'K7t-5WDZxgDrK_G-u' })
      .then(
        () => {
          setOpenSnackbar(true);
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box style={{margin:"3%"}} component="form" ref={form} onSubmit={sendEmail} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400 }}>
      <Typography variant="h6">Order (Cash on Delivery)</Typography>
      <TextField label="Enter Your Name" variant="outlined" name="client_name" fullWidth required />
      <TextField label="Enter Your Email" type="email" variant="outlined" name="client_email" fullWidth required />
      <TextField label="Enter Your Mobile Number" placeholder='e.g. 9315...' type="number" variant="outlined" name="client_number" fullWidth required />
      <TextField label="Enter Your Full Address" type="text" variant="outlined" name="client_address" fullWidth required />
      <TextField label="Enter PIN Code" type="text" variant="outlined" name="client_pin" fullWidth required />
      <TextField defaultValue={"Tiger Eye Pendant x 1 @ ₹399"} label="Order Details" multiline rows={4} variant="outlined" name="client_message" fullWidth required />
      <Button style={{width:"88vw", height:"50px", background:"rgb(255, 240, 0)", color:"black", borderRadius:"8px", boxShadow:"0 2px 5px 0 rgba(213,217,217,.5)", textTransform:"none", fontSize:"1.2rem"}} type="submit" variant="contained" color="primary">Click to Order</Button>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Order Successfully Sent, Check Email IN 24-48 hours for update, We will aim to deliver at your home within 5 working days
        </Alert>
      </Snackbar>
    </Box>
  );
};

// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';
// import { Button, TextField, Box, Typography } from '@mui/material';

// export const ContactUs = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs.sendForm('service_hko0v25', 'template_1l85095', form.current, { publicKey: 'K7t-5WDZxgDrK_G-u' })
//       .then(
//         () => {
//           alert("ORDER SUCCESSFULLY SENT, CHECK EMAIL IN 24-48 hours for update");
//           console.log('SUCCESS!');
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         }
//       );
//   };

//   return (
//     <Box style={{margin:"3%"}} component="form" ref={form} onSubmit={sendEmail} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400 }}>
//       <Typography variant="h6">Order (Cash on Delivery)</Typography>
//       <TextField required label="Enter Your Name" variant="outlined" name="client_name" fullWidth />
//       <TextField required label="Enter Your Email" type="email" variant="outlined" name="client_email" fullWidth />
//       <TextField required label="Enter Your Mobile Number" placeholder='e.g. 9315...' type="number" variant="outlined" name="client_number" fullWidth />
//       <TextField required label="Enter Your Full Address" type="text" variant="outlined" name="client_address" fullWidth />
//       <TextField required label="Enter PIN Code" type="text" variant="outlined" name="client_pin" fullWidth />
//       <TextField required defaultValue={"Tiger Eye Pendant x 1 @ ₹299 + shipping"} label="client_message" multiline rows={4} variant="outlined" name="client_message" fullWidth />
//       <Button type="submit" variant="contained" color="primary">Click to Order, Home Delivery</Button>
//     </Box>
//   );
// };


// // import React, { useRef } from 'react';
// // import emailjs from '@emailjs/browser';

// // export const ContactUs = () => {
// //   const form = useRef();

// //   const sendEmail = (e) => {
// //     e.preventDefault();

// //     emailjs
// //       .sendForm('service_hko0v25', 'template_1l85095', form.current, {
// //         publicKey: 'K7t-5WDZxgDrK_G-u',
// //       })
// //       .then(
// //         () => {
// //           console.log('SUCCESS!');
// //         },
// //         (error) => {
// //           console.log('FAILED...', error.text);
// //         },
// //       );
// //   };

// //   return (
// //     <form ref={form} onSubmit={sendEmail}>
// //       <label>Name</label>
// //       <input type="text" name="client_name" />
// //       <br/>
// //       <label>Email</label>
// //       <input type="email" name="client_email" />
// //       <br/>
// //       <label>Message</label>
// //       <textarea name="message" />
// //       <br/>
// //       <input type="submit" value="Send" />
// //     </form>
// //   );
// // };
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStateValue } from '../statemanagement/StateProvider';
import Clock from 'react-live-clock';

const LiveDashas = ({horoscope}) => {
  const [istTime, setIstTime] = useState('');
  const [_, dispatch] = useStateValue();
  const [dashaData, setDashaData] = useState([]);
  

  const planetEmojiMap = {
    'Sun': '🌞',
    'Moon': '🌝',
    'Mars': '🔴',
    'Mercury': '🪙',
    'Jupiter': '🪐',
    'Venus': '💫',
    'Saturn': '🌑',
    'Rahu': '🌀',
    'Ketu': '🌪️'
  };

  useEffect(() => {
    const calculateIstTime = () => {
      const now = new Date();
      const istTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
      const options = { 
        month: 'short', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit'
      };
      return istTime.toLocaleDateString('en-IN', options);
    };

    setIstTime(calculateIstTime());

    if (!!horoscope && !!horoscope.current_vdasha) {
      const currentVdasha = horoscope.current_vdasha;
      const liveDasha = [
        {
          title: 'Sookshmadasha',
          planet: currentVdasha.Sookshma.split(' ')[0],
          emoji: planetEmojiMap[currentVdasha.Sookshma.split(' ')[0]] || '🌟',
          from: currentVdasha.Sookshma.split('from ')[1].split(' to ')[0].trim(),
          to: currentVdasha.Sookshma.split('to ')[1].trim(),
          type: 'V. Short',
          detail: 'Live'
        },
        {
          title: 'Mahadasha',
          planet: currentVdasha.Mahadasha.split(' ')[0],
          emoji: planetEmojiMap[currentVdasha.Mahadasha.split(' ')[0]] || '🌟',
          from: currentVdasha.Mahadasha.split('from ')[1].split(' to ')[0].trim(),
          to: currentVdasha.Mahadasha.split('to ')[1].trim(),
          type: 'Long',
          detail: 'Major life phase'
        },
        {
          title: 'Antardasha',
          planet: currentVdasha.Antardasha.split(' ')[0],
          emoji: planetEmojiMap[currentVdasha.Antardasha.split(' ')[0]] || '🌟',
          from: currentVdasha.Antardasha.split('from ')[1].split(' to ')[0].trim(),
          to: currentVdasha.Antardasha.split('to ')[1].trim(),
          type: 'Mid',
          detail: 'Sub-period'
        },
        {
          title: 'Pratyantardasha',
          planet: currentVdasha.Pratyantar.split(' ')[0],
          emoji: planetEmojiMap[currentVdasha.Pratyantar.split(' ')[0]] || '🌟',
          from: currentVdasha.Pratyantar.split('from ')[1].split(' to ')[0].trim(),
          to: currentVdasha.Pratyantar.split('to ')[1].trim(),
          type: 'Short',
          detail: 'Sub-sub-period'
        }
      ];
      setDashaData(liveDasha);
    }

  }, [horoscope]);

  const currentPlanet = dashaData.length > 0 ? dashaData[0].planet : 'Loading...';
  const currentEmoji = planetEmojiMap[currentPlanet] || '🪐';

  return horoscope == null ? (<></>) : (
    <Accordion sx={{ boxShadow: 'none', fontSize: '0.8rem', marginTop:"12px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}>
        <Typography variant="body2" sx={{ fontWeight: '500' }}>
          {/* Live Dasha - {currentPlanet} {currentEmoji} | {istTime} */}
          {currentPlanet} {currentEmoji} Dasha | 
          <Clock
          style={{margin:"5px", fontSize:"1rem", fontWeight:"bold"}}
          // date={'1997-12-31T14:15:23+01:00'}
          format={'DD-MMM, h:mm:ss A'}
          timezone={'Asia/Kolkata'} ticking={true} /> 
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {dashaData.map((dasha, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <p style={{fontSize:"0.9rem"}}><strong>{dasha.title}</strong>  {dasha.detail}</p> <br/>
          
              <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                {dasha.planet} {dasha.emoji} | {dasha.from} to <strong>{dasha.to} </strong>
              </Typography> <br/>
             
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LiveDashas;

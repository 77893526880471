import React from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{ fontWeight: 400 }}>Privacy Policy</h1>
                            <br/>
                            <p><i>Last updated: April 28th, 2024</i></p>
                            <br /><br />
                            <p style={{ fontSize: "12px" }}>
                                The privacy policy delineates the manner in which AstroSky ("we", "us", or "our") gathers, utilizes, discloses, and safeguards your information upon your usage of our products, website, and services (collectively referred to as the "Services").
                            </p>
                            <br />
                            <h3>What Data We Collect</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                We may collect the following information from you:
                                <ul>
                                    <li>Place of Birth that you provide to us</li>
                                    <li>Birth day, month and year that you provide to us</li>
                                    <li>Time of birth that you provide to us</li>
                                </ul>
                                Note: We do not store this information, we use it to provide the service
                            </p>
                            <br />
                            <h3>How We Use Your Data</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                We use third party analytics services such as Google Analytics to collect in an anonymized manner UI elements such as buttons and text-field interaction and data entry to:
                                <ul>
                                    <li>Understand user experience while navigating our website</li>
                                    <li>Improve our products and services</li>
                                    <li>To enhance your overall user experience</li>
                                </ul>
                            </p>
                            <br />
                            <h3>Data Sharing and Disclosure</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                Your data might be shared with third parties under the following circumstances:
                                <ul>
                                    <li>Regulatory or law enforcement agencies, as required by law.</li>
                                    <li>Our business partners or affiliates, where it aligns with our policies and your preferences.</li>
                                </ul>
                            </p>
                            <br />
                            <h3>Cookie Policy</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                Cookies are used on our website to enhance user experience and analyze site usage. You can manage your cookie preferences through your browser settings.
                            </p>
                            <br />
                            <h3>Your Rights</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                You have the right to:
                                <ul>
                                    <li>Access and review your data.</li>
                                    <li>Request corrections or updates to your data.</li>
                                    <li>Withdraw consent for the use of your data at any time, subject to legal or contractual restrictions.</li>
                                </ul>
                                Please contact us at <a href="mailto:support@astrosky.co.in">support@astrosky.co.in</a> to exercise these rights.
                            </p>
                            <br />
                            <h3>Data Retention and Security</h3>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                We retain your information as long as necessary to provide services to you or as required by law. We use appropriate security measures to protect your data from unauthorized access or disclosure.
                            </p>
                            <br />
                            <h2>Contact Us</h2>
                            <br />
                            <p style={{ fontSize: "12px" }}>
                                For any questions or grievances regarding our privacy practices, please reach out to us via the contact details provided on our website.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/>
            <AppFooter/>
        </div>
    );
};

export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authenticateWithFirebasePlusUpdateFirestore } from './AuthenticateUser';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import AppFooter from './AppFooter';
import Header from './Header';
import AtomicSpinner from 'atomic-spinner';
import OpenWhatsAppButton from '../components/OpenWhatsappButton';

const SignInHeadless = () => {
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSectionVisible, setOtpSectionVisible] = useState(false);
  const [OTPlessSignin, setOTPlessSignin] = useState(null);
  const location = useNavigate();

  const [loading, setLoading] = useState(false);

  const [{userCred}, dispatch] = useStateValue();

  const uatUrl = "https://astroservice-uat.azurewebsites.net/";
  const prodUrl = "https://astroservice.azurewebsites.net";
  //const prodUrl = "https://astroservice-uat.azurewebsites.net/";;

  useEffect(()=>{
    console.log("component has mounted");
  },[])

  useEffect(() => {
    const callback = async (userinfo) => {
        setLoading(true);

      const getVerifiedOTPLessObject = await fetch(`${prodUrl}/verify`,{
        method:"POST",
        body: JSON.stringify({"token": userinfo.token}),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    });

    const verifiedOTPLessObject = await getVerifiedOTPLessObject.json();  
    
    //extract phone number
    const verifiedPhoneNumber = verifiedOTPLessObject.phone_number;
    

    const phoneToHashResponse = await fetch(`https://us-central1-spiritualtech-7c560.cloudfunctions.net/hashNumber?number=${verifiedPhoneNumber}`);

    const phoneHashObject = await phoneToHashResponse.json();
    
    //convert phone => hash
      const phoneHashFirestoreKey = phoneHashObject.hash;
   //const phoneHashFirestoreKey = "d3bc8f1ef09f13ee3d7efc36aeb66ac294825fea918f809c18d6668d9db80dd4";

    dispatch({type: actionTypes.SET_UID, uid: phoneHashFirestoreKey});  //dispatch, listen to future upates to user object in db (triggers the listen)

    const userCred = await authenticateWithFirebasePlusUpdateFirestore(verifiedPhoneNumber, phoneHashFirestoreKey); 
    dispatch({type: actionTypes.SET_USER_CRED, userCred: userCred});//comes from firebase, store to state 

    localStorage.setItem("uid", phoneHashFirestoreKey); 
    //setLoading(false);
    setLoading(false);
    location("/profile");


      // Implement your custom logic here.
    };

    try{
      if (!document.getElementById('otpless-sdk')) {

        const script = document.createElement('script');
        script.id = 'otpless-sdk';
        script.type = 'text/javascript';
        script.src = 'https://otpless.com/v2/headless.js';
        script.setAttribute('data-appid', '80RGF29764YZU0P0IVMD');
        script.onload = () => {
          const OTPlessSigninInstance = new window.OTPless(callback);
          setOTPlessSignin(OTPlessSigninInstance);
        };
        document.head.appendChild(script);
      } else {
        const OTPlessSigninInstance = new window.OTPless(callback);
        setOTPlessSignin(OTPlessSigninInstance);
      }
    } catch(error){
      location("/");
      console.log("Failed to authenticate");
    }

    

  }, []);

  const phoneAuth = () => {
    if (OTPlessSignin) {
  
      OTPlessSignin.initiate({
        channel: 'PHONE',
        phone: mobile,
        countryCode: '+91',
      });
      setOtpSectionVisible(true);
    } else {
      console.error('OTPlessSignin is not initialized');
    }
  };

  const verifyOTP = () => {
    setLoading(true);
    // Implement OTP verification logic here
    console.log('Verifying OTP:', otp);
    OTPlessSignin.verify({
        channel: "PHONE",
        phone: mobile,
        otp: otp,
        countryCode: "+91",
      });
  };

  const oauth = (provider) => {
    if (OTPlessSignin) {
      OTPlessSignin.initiate({
        channel: 'OAUTH',
        provider,
      });
    } else {
      console.error('OTPlessSignin is not initialized');
    }
  };

  return  <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} userCred={userCred} />
                <div style={{ marginTop: "6%" }}>
                
                {(loading) && <div style={{textAlign:"center"}}><AtomicSpinner/></div>}
               
                {(!loading) && <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <br/>
                    <img src="/icons/horoscope.png" width="70px" /> <br/>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    
                    <b style={{ fontSize: '1.3rem', display: 'flex', alignItems: 'center', gap: '10px' }}>
                      Secure Sign with WhatsApp 
                      <img 
                        src="/icons/whatsapp.png" 
                        alt="WhatsApp" 
                        style={{ width: '40px', textDecoration: 'none' }} 
                      />
                    </b>
                  </div>
                   
                {/* <p style={{fontWeight:"500", fontSize:"1.3em"}}>Enter mobile number below: </p> */}
                    <TextField
                        id="mobile-input"
                        label="Enter Your mobile number"
                        variant="outlined"
                        value={mobile} style={{margin:"5%"}}
                        onChange={(e) => setMobile(e.target.value)}
                    />
                    
                    <Button variant="contained" onClick={phoneAuth} style={{width:"50%"}}>
                        Get OTP
                    </Button>



                    {otpSectionVisible && (
                        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                            <br/> 

                            {/* <p style={{fontWeight:"500"}}>Enter OTP sent to WhatsApp </p> */}
                       
                             {/* <OpenWhatsAppButton/> */}
                        <TextField
                            id="otp-input"
                            label="Enter OTP sent to WhatsApp"
                            variant="outlined"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                       
                        <Button variant="contained" onClick={verifyOTP} style={{width:"50%", margin:"5%"}}>
                            SUBMIT
                        </Button>

                        <br/>

                        <p style={{fontWeight:"500"}}>Can't find OTP? Open WhatsApp to Get OTP <br/> <br/> <OpenWhatsAppButton/></p>
                            <br/>
                        

                        

                        </Box>
                    )}

                    {/* <Button variant="outlined" onClick={() => oauth('WHATSAPP')}>
                        Continue with WhatsApp
                    </Button> */}
                    {/* <Button variant="outlined" onClick={() => oauth('GMAIL')}>
                        Continue with Gmail
                    </Button> */}
                    {/* Add more buttons for different OAuth providers as needed */}
                    </Box>}

                    </div>
            </div>
            <br/><br/><br/><br/>
            <AppFooter/>
        </div>
  
};

export default SignInHeadless;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './blogDetails.css';
import AtomicSpinner from 'atomic-spinner';

const BlogDetail = () => {
  const { path } = useParams(); // Use path instead of id
  const [blog, setBlog] = useState(null);
  const API_URL = 'https://steadfast-cows-9445c3a50f.strapiapp.com/api/blogs';

  useEffect(() => {
    const fetchBlogByPath = async () => {
      try {
        // Fetch the blog by path
        const response = await axios.get(`${API_URL}?filters[path][$eq]=${path}&populate=*`);
        if (response.data && response.data.data.length > 0) {
          setBlog(response.data.data[0]); // Set the first blog that matches the path
        } else {
          console.error('Blog not found', response);
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };
    fetchBlogByPath();
  }, [path]);

  if (!blog) {
    return <div className='spinner'>
            <AtomicSpinner/>
          </div>
  }

  const blogContent = blog.attributes.content;
  const blogThumbnailData = blog.attributes.thumbnail?.data;
  const blogThumbnail = blogThumbnailData && blogThumbnailData.length > 0 
    ? blogThumbnailData[0].attributes.url 
    : '';

    const renderContent = (content) => {
      return content.map((item, index) => {
        switch (item.type) {
          case 'heading':
            const HeadingTag = `h3`;
            return <HeadingTag key={index} style={{ fontWeight: item.children[0].bold ? 'bold' : 'normal' }}>{item.children[0].text}</HeadingTag>;
          case 'paragraph':
              return (
                <p key={index}>
                  {item.children.map((child, idx) => {
                    if (child.type === 'link' && child.url) {
                      console.log(child);
                      return (
                        <a href={child.url} key={idx} target="_blank" rel="noopener noreferrer">
                          {child.children.map(grandchild => grandchild.text).join('')}
                        </a>
                      );
                    }
                    return child.text;
                  })}
                </p>
              );
            default:
              return null;
      }
      });
    };

  return (
    <div className="blog-detail">
      <div className="blog-content">
        <h1>{blog.attributes.title}</h1>
        {blogThumbnail && (
          <img
            src={blogThumbnail}
            alt={blog.attributes.title}
            className="blog-detail-image"
          />
        )}
        {renderContent(blogContent)}
      </div>
    </div>
  );
};

export default BlogDetail;

import React from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';
import { Button } from '@mui/material';

const MatrimonyHeadlines = () => {
    return (
      <div>
        <br/>
        <h3 style={{fontWeight:"400", fontSize:"32px"}}>Personalized Astrological Matches, Just for you! </h3> <br/><br/>
        <h3 style={{fontWeight:"400", fontSize:"22px"}}>Coming Soon on AstroSky</h3>
      </div>
    );
  }

  const MatrimonyCopy = () => {
    return (
      <div>
        <br/>
        <h3 style={{fontWeight:"400", fontSize:"22px"}}>Access to our extensive Database</h3> <br/>
        <h3 style={{fontWeight:"400", fontSize:"22px"}}>AI powered advanced matching</h3> <br/>
        <h3 style={{fontWeight:"400", fontSize:"22px"}}>We will combine Your Astrological Chart and preferences to find the perfect life partner</h3> <br/>
      </div>
    );
  }

const Matrimony = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} />
                <div style={{ marginTop: "5%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "5%" }}>
                        <MatrimonyHeadlines/>
                            <br/>
                            <div style={{textAlign:"center"}}> 
                                <img src="/matrimony.webp" style={{width:"100%"}}/>
                            </div>
                           <br/>
                            
                            <Button style={{width:"88vw", height:"50px", background:"rgb(255, 240, 0)", color:"black", borderRadius:"8px", boxShadow:"0 2px 5px 0 rgba(213,217,217,.5)", textTransform:"none", fontSize:"1rem"}} onClick={(_) =>{
                                window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSd3JebrFU2p2SvllKgQXOcL42YUfcUT2On6DPuxMAjCAngY-Q/viewform?usp=sf_link";
                            } }>Submit Profile to AstroSky & Join Waitlist</Button>
                           <br/>
                           <br/>
                           <MatrimonyCopy/>
                          
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/>
            <AppFooter showHome={false}/>
        </div>
    );
};

export default Matrimony;


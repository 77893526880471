import React from 'react';
//import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';

const TermsOfService = () => {

  const TermsAndConditions = () => {
    return (
      <div className="terms-and-conditions" style={{margin:"3.5%"}}>
        <h2>Terms & Conditions</h2> <br/> <br/>
        <p style={{ fontSize: "12px" }}><i>Last updated: April 28th, 2024</i></p> <br/> <br/>
        <p style={{ fontSize: "12px" }}>These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding agreement by and between ARCENIA TECH PRIVATE LIMITED, (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) and relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”).</p>
        <p style={{ fontSize: "12px" }}>By using our website and availing the Services, you agree that you have read and accepted these Terms (including the Privacy Policy). We reserve the right to modify these Terms at any time and without assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates.</p>
        <br/>
        <h2>Terms of Use</h2> 
        <br/>
        <p style={{ fontSize: "12px" }}>The use of this website or availing of our Services is subject to the following terms of use:</p>
        <br/>
        <ul>
          <li style={{ fontSize: "12px" }}>To access and use the Services, you agree to provide true, accurate and complete information to us during and after registration, and you shall be responsible for all acts done through the use of your registered account.</li>
          <li style={{ fontSize: "12px" }}>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials offered on this website or through the Services, for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
          <li style={{ fontSize: "12px" }}>Your use of our Services and the website is solely at your own risk and discretion. You are required to independently assess and ensure that the Services meet your requirements.</li>
          <li style={{ fontSize: "12px" }}>The contents of the Website and the Services are proprietary to Us and you will not have any authority to claim any intellectual property rights, title, or interest in its contents.</li>
          <li style={{ fontSize: "12px" }}>You acknowledge that unauthorized use of the Website or the Services may lead to action against you as per these Terms or applicable laws.</li>
          <li style={{ fontSize: "12px" }}>You agree to pay us the charges associated with availing the Services.</li>
          <li style={{ fontSize: "12px" }}>You agree not to use the website and/or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or Indian or local laws that might apply to you.</li>
          <li style={{ fontSize: "12px" }}>You agree and acknowledge that website and the Services may contain links to other third party websites. On accessing these links, you will be governed by the terms of use, privacy policy and such other policies of such third party websites.</li>
          <li style={{ fontSize: "12px" }}>You understand that upon initiating a transaction for availing the Services you are entering into a legally binding and enforceable contract with us for the Services.</li>
          <li style={{ fontSize: "12px" }}>You shall be entitled to claim a refund of the payment made by you in case we are not able to provide the Service. The timelines for such return and refund will be according to the specific Service you have availed or within the time period provided in our policies (as applicable). In case you do not raise a refund claim within the stipulated time, then this would make you ineligible for a refund.</li>
        </ul>
        <br/>
        <p style={{ fontSize: "12px" }}>Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to perform an obligation under these Terms if performance is prevented or delayed by a force majeure event.</p>
        <br/>
        <p style={{ fontSize: "12px" }}>These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and construed in accordance with the laws of India.</p>
        <br/>
        <p style={{ fontSize: "12px" }}>All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Gurgaon, Haryana.</p>
        <br/>
        <p style={{ fontSize: "12px" }}>All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.</p>
      </div>
    );
  };

  //const navigate = useNavigate();

  return (
    <div>
      <div className="container">
        <Header showLogin={false} />
        <TermsAndConditions/>
        {/* <div style={{ marginTop: '2%' }}>
          <div className="">
            <div className="privacy-policy" style={{ marginTop: '7%' }}>
              <h1 style={{ fontWeight: 400 }}>Terms of Service </h1> <br/>
              <i style={{fontSize:"0.8rem"}}>*including Cancellation and Refund Policy</i><br/>
              <br />
              <p>
                Last updated: December 4th, 2023
              </p>
              <br /><br />
              <p>
                Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the www.chatbharat.ai website (the “Service”) operated by ChatBharat.ai (“us”, “we”, or “our”). 
              </p>
              <br/>
              <p>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
              </p>
              <br/>
              <p>
                By accessing or using the Service, you agree to be bound by these Terms. ChatBharat.ai reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
              </p>
              <br/>
              <h2>Our Services</h2>
              <br />
              <p>
                ChatBharat.ai provides a text generation service for writing assistance. ChatBharat.ai is available via <a href="https://www.chatbharat.ai">https://www.chatbharat.ai</a> and associated internet domains.
              </p>
              <br/>
              <h2>Acceptable Use</h2>
              <br />
              <p>
                You must be at least 13 years old to use the Service. If you are under 18, you must have your parent or legal guardian’s permission to use the Service. You agree not to share, transfer, or sell your account. You are responsible for all activities on your account.
              </p>
              <p>
                You agree not to use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service. You agree not to use the Service for unlawful, illegal, fraudulent, or harmful activities, including but not limited to hate, harassment, violence, political manipulation, spam, or malware.
              </p>
              <p>
                You agree not to conduct any systematic or automated data collection activities (including scraping, data mining, data extraction, or data harvesting) on or in relation to the Service. Prohibited data collection includes, but is not limited to, using the Service as input into other services, websites, or databases. You agree not to republish any content generated by the Service without clearly citing the Service as well as the context associated with the content. Misrepresentation of the source of the content or the nature of its creation is strictly prohibited.
              </p>
              <br/>
              <h2>Billing</h2>
              <br/>
              <p>
                 Billing. If you purchase any Services, you will provide complete and accurate billing information, including a valid payment method. You’re responsible for all applicable taxes, and we’ll charge tax when required. If your payment cannot be completed you will not be able to access our Services until payment is received. 
              </p>
              <br/>
              <h2>Refund Policy</h2>
              <br/>
              <p>
                 Payments are non-refundable, except where required by law. These Terms do not override any mandatory local laws regarding your cancellation rights. 
              </p>
              <br/>
              <h2>Discontinuation of Services</h2>
              <br/>
              <p>
              
               We may decide to discontinue our Services, but if we do, we will give you advance notice and a refund for any prepaid, unused Services.
              </p>
              <br/>
              <h2>Cancellation Policy</h2>
              <br />
              <p>
                You can cancel your WriteChat.ai account at any time by contacting our support team at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>. Please note that certain terms and conditions may apply to account cancellations, and any applicable fees or refunds will be subject to our cancellation policy.
              </p>
              <br/>
              <h2>Contact Us</h2>
              <br />
              <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@writechat.ai">support@chatdragon.ai</a>.</p>
              <br/>
            </div>
          </div>
        </div> */}
      </div>
      <br/> <br/>  <br/> <br/> <br/> <br/>
      <AppFooter />
    </div>
  );
};

export default TermsOfService;


import { getUrl } from "../../helpers/envUrls";

const uatUrl = "https://astroservice-uat.azurewebsites.net";
const localUrl = "http://localhost:8090";
//const prodUrl = "https://astroskyprod.azurewebsites.net";

//const prodUrl = "https://astroservice.azurewebsites.net/";
const prodUrl = "https://astroindia.azurewebsites.net/";

const fetchHoroscope = async (dob, tob, lat, lon) => {
    try {
        //console.log("Fetching from");
        //console.log(`${uatUrl}`);
        const horoscopeResponse = await fetch(`${getUrl()}/get-horoscope-detail`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ "userDetail": { dob: dob, tob: tob, lat: lat, lon: lon } })
        })
        const data = await horoscopeResponse.json();
        return data;

    } catch (e) {
        console.log("error in fetching horoscope");
        return null;
    }
}

const fetchHoroscopeData = async (dob, tob, lat, lon, horoscope) => {
    try {

        if((horoscope == null) || (generateHoroscopeSignature(horoscope.dob, horoscope.tob, horoscope.lat, horoscope.lon) != generateHoroscopeSignature(dob, tob, lat, lon))){ 
          const h_result = await fetchHoroscope(dob,tob,lat,lon); 
          return h_result;
         // dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_result, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
        } else{
            return horoscope;
        }

    } catch (e) {
        console.log("error in fetching horoscope");
        return null;
    }
}

// const fetchHoroscopeData = async (dob, tob, lat, lon, horoscope) => {
//     try {

//         const h_result = await fetchHoroscope(dob,tob,lat,lon); 
//         return h_result;

//         // if((horoscope == null) || (generateHoroscopeSignature(horoscope.dob, horoscope.tob, horoscope.lat, horoscope.lon) != generateHoroscopeSignature(dob, tob, lat, lon))){ 
//         //   const h_result = await fetchHoroscope(dob,tob,lat,lon); 
//         //   return h_result;
//         //  // dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_result, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
//         // } else{
//         //     return horoscope;
//         // }

//     } catch (e) {
//         console.log("error in fetching horoscope");
//         return null;
//     }
// }

/**
 * 
 * dob: "YYYY-MM-DD",
 * tob: "HH:MM:SS",
 * geo: {lat: 0.00000000, lon: 0.0000000000},
 */
const generateHoroscopeSignature = (dob, tob, lat, lon) => {
    // Round latitude and longitude to two decimal places
    const roundedLat = lat.toFixed(2);
    const roundedLon = lon.toFixed(2);
    
    // Concatenate dob, tob, roundedLat, and roundedLon
    const sig = dob + tob + roundedLat + roundedLon;
    return sig;
};

export { fetchHoroscope, generateHoroscopeSignature, fetchHoroscopeData };
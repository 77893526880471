import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import AppFooter from './AppFooter';
import { Button } from '@mui/material';
import { TypeAnimation } from "react-type-animation";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Refer = () => {
    const navigate = useNavigate();

    const [shared, setShared] = useState(false);

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} />
                <div style={{ marginTop: "5%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "5%" }}>
                            <TypeAnimation sequence={[
                                'Refer AstroSky & Earn ₹₹₹',
                            ]} wrapper="span" speed={50} style={{ marginBottom: "15px", textAlign: "center", fontSize: "1.2em", fontWeight: "500", lineHeight: "2.1rem", display: "inline-block", color: "black" }} repeat={0} />

                            <br />
                            <br />

                            <h3>Refer AstroSky using the button below</h3> <br />

                            <Button
                                style={{
                                    paddingRight: "20px",
                                    color: "black",
                                    textTransform: "none",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={async () => {
                                    try {
                                        const url = "https://astrosky.co.in/";
                                        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                                        window.open(whatsappUrl, '_blank');
                                        window.gtag('event', 'Share');
                                        setShared(true);
                                    } catch (error) {
                                        console.error("Failed to share");
                                    }
                                }}>
                                <Button style={{ width: "88vw", height: "50px", background: "rgb(255, 240, 0)", color: "black", borderRadius: "8px", boxShadow: "0 2px 5px 0 rgba(213,217,217,.5)", textTransform: "none", fontSize: "1rem" }}
                                    startIcon={<img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "35px", textDecoration: "none" }} />}

                                >Share & Earn*</Button>
                            </Button>
                            <br />

                            <br />
                            <h3 style={{ fontWeight: "400", fontSize: "1rem" }}>If Referred Individual makes a purchase on AstroSky, Claim 20% of the purchase amount using below</h3> <br />



                            <Button style={{ width: "88vw", height: "50px", background: "black", color: "white", borderRadius: "8px", boxShadow: "0 2px 5px 0 rgba(213,217,217,.5)", textTransform: "none", fontSize: "1rem" }} onClick={() => {
                                if (shared) {
                                    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSc77HaYju4naZRcUr-rrur0GNr6gAahHg2cZHK9qYxERbrHhQ/viewform?usp=sf_link";
                                }
                            }}>Submit Claim*</Button>
                            <br />
                            <br />
                            <br />

                            <div style={{ display: "flex" }}> <div style={{ margin: "auto" }}>
                                <hr style={{ border: 'none', background: "rgba(128, 128, 128, 0.2)", height: "1px", margin: '20px 0 !important', width: "90vw" }} />
                            </div></div> <br /> <br />

                            <TypeAnimation sequence={[
                                'Share on Social Media & Earn',
                            ]} wrapper="span" speed={50} style={{ marginBottom: "15px", textAlign: "center", fontSize: "1.2em", fontWeight: "500", lineHeight: "2.1rem", display: "inline-block", color: "black" }} repeat={0} />
                            <br /> <br />
                            <Button style={{ width: "88vw", height: "50px", background: "rgb(255, 240, 0)", color: "black", borderRadius: "8px", boxShadow: "0 2px 5px 0 rgba(213,217,217,.5)", textTransform: "none", fontSize: "1rem" }}
                                startIcon={<img src="/icons/social-media.png" alt="WhatsApp" style={{ width: "35px", textDecoration: "none" }} />}
                                onClick={(_) => {
                                    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdHux4r24t9QfSDQPH-1VpBxNpm7hP8kTGIZJ1WZWAp5RvAgw/viewform?usp=sf_link";
                                }}
                            >Share on Social Media & Earn</Button> <br /> <br /> <br/>

                            <i style={{ fontSize: "11px" }}>*Disclaimer: AstroSky reserves the right to transfer up to 20% of the purchase order value, with a maximum of ₹500 that can be claimed by a single person. Any attempt to manipulate the referral program will result in the disqualification of the claim. Only one claim is allowed per order; if multiple claims are received for a single order, all claims for that order will be disqualified. AstroSky retains full discretion to disqualify any claimant on any grounds. If the claim is verified and approved by AstroSky, the amount will be transferred to the claimant within 30 days of approval.</i>


                            <br /> <br /><br />

                            <div style={{ textAlign: "center" }}>
                                <img src="https://astrosky.blob.core.windows.net/astrosky/yogi.png" style={{ width: "300px", borderRadius: "10%" }} />
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br /><br />
            <AppFooter home={true} />
        </div>
    );
};

export default Refer;

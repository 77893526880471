import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import { useEffect } from "react";
import "../pages/BasicChat.css";
import "./PlacesAutocomplete.css";


const PlacesAutocomplete = ({edit=false}) => {


  useEffect(() => {

      const fetchData = async () => {
        try {
          //dup
          const cityCache = localStorage.getItem("city");
          const latCache = localStorage.getItem("lat");
          const lonCache = localStorage.getItem("lon");
          if(!!cityCache && !!latCache && !!lonCache){
              dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:Number(latCache), lon:Number(lonCache)}});
              dispatch({type: actionTypes.SET_USER_CITY, city: cityCache});
              return;
          }

          if(city != "" && geo.lat != 0 && geo.lon != 0){
            console.log("returning, city and geo are already set, no need to re-fetch from IP");
            return;
          }
              const response = await fetch('https://ipinfo.io/json');
              const data = await response.json();
              const results = await getGeocode({ address: data.city });
              const { lat, lng } = getLatLng(results[0]);

              dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:lat, lon:lng}});
              dispatch({type: actionTypes.SET_USER_CITY, city: data.city});

        } catch (error) {
            console.error("Error getting city from IP:", error);
        }
    };

    fetchData();
  }, []);


  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "YOUR_CALLBACK_NAME",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const [{city, geo}, dispatch] = useStateValue();

  useEffect(()=>{
    setValue(city, false); 
    clearSuggestions();
  },[city, geo]) //depends on new city, geo 

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    window.gtag('event', 'Places');
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:lat, lon:lng}});
      dispatch({type: actionTypes.SET_USER_CITY, city: description});
      
      //UpdateCityAndGeoInDB(city, geo)
    });
  };



  //when location changes, update cache
  useEffect(()=>{
    localStorage.setItem("city", city);
    localStorage.setItem("lat", geo.lat);
    localStorage.setItem("lon", geo.lon);
  },[geo, city])

  return (
    <> <div style={{fontSize:"large"}}>
      
      {!edit && <p style={{fontWeight:"500"}}>Enter Your Place of Birth </p>}
      {edit && <p style={{fontWeight:"500"}}>Enter Your Place of Birth  </p>}
      </div><br/> 
    <div ref={ref} className="autocompleteContainerStyle">
      <input
        className="inputStyle"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter Place of Birth"
      />
      {status === "OK" && (
        <div className="dropdownContainerStyle">
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <div
                key={place_id}
                className="dropdownItemStyle"
                onMouseDown={handleSelect(suggestion)} // Changed to onMouseDown for better UX
              >
                <strong>{main_text}</strong> <small>{secondary_text}</small>
              </div>
            );
          })}
        </div>
      )}
    </div>
    </>
  );
};

export default PlacesAutocomplete;

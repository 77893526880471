/**
 * 
 * Checks if number fits pattern 
 * +, separators, indian numbers all supported
 * input: number, must be a string
 * output: bool, true => number fits pattern and is a valid number, false => number does not fit the pattern and is not a valid phone number
 */
export const verifyPhoneNumberPattern = (number) => {
    const validPattern = /^\+?\d{1,3}?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
    return validPattern.test(number);
}


export const generateReferralCode = () => {
    let code = '';
    for(let i=0; i<10; i++){
        code+= Math.floor(Math.random()*10)
    }
    return code;
}
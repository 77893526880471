import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const SpeechToText = ({ trigger, setListening, setTranscript }) => {
  const [isListening, setIsListening] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setListening(isListening);
  }, [isListening, setListening]);

  useEffect(() => {
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onstart = () => {
      console.log('Voice recognition started');
    };

    recognition.onresult = (event) => {
      clearTimeout(timeoutRef.current);
      const transcript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('');
      //console.log(transcript);
      setTranscript(transcript);

      timeoutRef.current = setTimeout(() => {
        recognition.stop();
        //alert('done');
        if(transcript.length>0){
         window.gtag('event', 'Voice');
         trigger(transcript);
        }
      }, 4000);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error', event);
    };

    recognition.onend = () => {
      console.log('Voice recognition ended');
      setIsListening(false);
    };

    return () => {
      recognition.onstart = null;
      recognition.onresult = null;
      recognition.onerror = null;
      recognition.onend = null;
      recognition.stop();
      clearTimeout(timeoutRef.current);
    };
  }, [setTranscript]);

  const handleButtonClick = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (e) {
      console.log("mic access denied");
      window.gtag('event', 'Ask_Reject');
      return;
    }
    window.gtag('event', 'Ask_Accept');
    console.log("browser has granted mic access");

    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  return (
    <Button className='refine_button' startIcon={<MicIcon sx={{ color: isListening ? "red" : "inherit", fontSize:"1.3rem !important" }} />} onClick={handleButtonClick}>
      {isListening ? 'Stop' : 'Ask'}
    </Button>
  );
};

export default SpeechToText;

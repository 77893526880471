import React from "react";
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import MobileDateComponent from "./MobileDateComponent";
import Disclaimer from "../components/Disclaimer";
import Snackbar from '@mui/material/Snackbar';
import UserListener from "./FirestoreListener/userListener";
import TestimonialsCarousel from "./TestimonialsCarousel";
import { fetchHoroscope, generateHoroscopeSignature } from "../helper/fetchHelpers/fetchHelpers";
import RowRadioButtonsGroup from "../components/RowRadioButtonsGroup";

function EditHoroscopeDetails() {
    const [index, setIndex] = useState(0);
    const [{ dob, tob, geo, horoscope, gender }, dispatch] = useStateValue();
    const navigate = useNavigate();
    //UserListener();

    const [open, setOpen] = useState(false);
    const [hasEditedDateTime, setHasEditedDateTime] = useState(false);
    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    return (
        <div className="astroSkyLandingPageCommon">
           <Header showLogin={true} />
            <div className="container">
                
                <div className="container_body" >


                    {/* <Snackbar
                        open={open}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        autoHideDuration={10000}
                        onClose={() => setOpen(false)}
                        message="Enter your Birth Details then start Chat"
                    /> */}

                    <div style={{padding:"0.9rem"}}>
                        
                        <MobileDateComponent edit={true} setHasEditedDateTime={setHasEditedDateTime} />
                        
                        <PlacesAutocomplete edit={true} />

                        

                        <RowRadioButtonsGroup dispatch={dispatch} gender={gender}/> <br/> <br/>

                        <Button className="chatStyle" onClick={async (_) => {

                            navigate("/");
                            
                            }}>View Astrologers</Button>

                            <br/> <br/> <br/>

<i style={{fontSize:"0.9rem"}}>* Birth Date, Time and City will be saved</i><br/><br/> <br/>
                        
                        {/* <Button className="chatStyle" onClick={async (_) => {

                            console.log("Attempting to fetch horoscope");
                            
                            if(!horoscope || horoscope.dob != dob ){ //horoscope in store is null or is out of date 
                                console.log("fetch new horoscope for user");
                                const h_result = await fetchHoroscope(dob,tob,geo.lat,geo.lon);
                                dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {h_result, dob:dob}});
                            }else{
                                console.log("use current horoscope from state");
                            }

                             //store horoscope
                            
                                
                            console.log("Horoscope fetched");

                           
                            }}>Chat</Button> */}


                            {/* <Button className="chatStyle" onClick={async (_) => {

                                // generateHoroscopeSignature(horoscope.dob, horoscope.tob, horoscope.lat, horoscope.lon);
                                // generateHoroscopeSignature(dob, tob, geo.lat, geo.lon)

                                // try{
                                //     if((horoscope == null) || (generateHoroscopeSignature(horoscope.dob, horoscope.tob, horoscope.lat, horoscope.lon) != generateHoroscopeSignature(dob, tob, geo.lat, geo.lon))){ 
                                //         const h_result = await fetchHoroscope(dob,tob,geo.lat,geo.lon); 
                                //         dispatch({type: actionTypes.SET_HOROSCOPE, horoscope: {...h_result, dob:dob, tob:tob, lat:geo.lat, lon:geo.lon}});
                                //     }
                                // } catch(e){
                                //     console.log("failed to fetch horoscope");
                                // }
                               

                                window.gtag('event', 'Talk');
                                dispatch({type: actionTypes.SET_ENTITY, entity: "default"}); // coupled with chat
                                dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
                                dispatch({ type: actionTypes.SET_LOADING, loading: true });
                                navigate("/query");
                                
                                }}>Chat</Button> */}

                        {/* <Button className="chatStyle">Save</Button> */}
                    </div>
                    
                  
                    <div>

                        {/* <Button className="chatStyle" onClick={(_) => {

                            if (dob == '' || geo.lat == 0 || geo.lon == 0) {
                                setOpen(true);
                            }
                            else {
                                window.gtag('event', 'Talk');
                                dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
                                dispatch({ type: actionTypes.SET_LOADING, loading: true });
                                navigate("/query");
                            }

                        }}>Chat</Button> */}
                        {/* <br /> */}
                        {/* <br /> */}
                        {/* <div style={{ textAlign: "center" }}>
                            <br />
                            <img className="halo" src={"/yogi.png"} onClick={() => {
                                if (dob == '' || geo.lat == 0 || geo.lon == 0) {
                                    setOpen(true);
                                }
                            }} style={{ borderRadius: "20px", width:"90%" }} />
                            <br /><br />
                        </div> */}
                    </div>
                    {/* <TestimonialsCarousel /> */}
                    {/* <Disclaimer hindi={false} /> */}
                    <br /> <br /> <br /><br /><br />
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} showBack={false} />
        </div>
    );
}

export default EditHoroscopeDetails;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AtomicSpinner from 'atomic-spinner';
import './contentDetails.css';
import { Helmet } from 'react-helmet-async';

const ContentDetails = () => {
  const { path } = useParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = 'https://steadfast-cows-9445c3a50f.strapiapp.com/api/contents';

  useEffect(() => {
    const fetchContentById = async () => {
      try {
        console.log('Fetching content for path:', path); // Debug log
        const response = await axios.get(`${API_URL}?filters[path][$eq]=${path}&populate=*`);
        console.log('API Response:', response.data); // Debug log
        
        const data = response.data.data;
        if (data && data.length > 0) {
          setContent(data[0].attributes); // Adjusted to access first item attributes
        } else {
          setError('Content not found');
        }
      } catch (error) {
        setError('Error fetching content');
        console.error('Error fetching content:', error); // Debug log
      } finally {
        setLoading(false);
      }
    };
    
    fetchContentById();
  }, [path]);

  if (loading) {
    return (
      <div className='spinner'>
        <AtomicSpinner />
      </div>
    );
  }

  if (error) {
    return <div className='error'>{error}</div>;
  }

  if (!content) {
    return <div className='error'>Content not found</div>;
  }
  const { title, description, seo } = content;
  const { metaTitle, metaDescription, primaryKeywords,H1, H2, secondaryKeywords, canonicalURL } = seo[0] || {};
  console.log(metaTitle, " --metatitle", title, " ----title");
  console.log(metaDescription, " --description");
  console.log(primaryKeywords, " ---prim key");
  const renderDescription = (desc) => {
    return desc.map((item, index) => {
      switch (item.type) {
        case 'heading':
          const HeadingTag = `h3`;
          return <HeadingTag key={index}>{item.children.map(child => child.text)}</HeadingTag>;
        case 'paragraph':
          return (
            <p key={index}>
              {item.children.map((child, idx) => {
                if (child.type === 'link' && child.url) {
                  return (
                    <a href={child.url} key={idx} target="_blank" rel="noopener noreferrer">
                      {child.children.map(grandchild => grandchild.text).join('')}
                    </a>
                  );
                }
                return child.text;
              })}
            </p>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="content-detail">
      <Helmet>
        <title>{metaTitle || title}</title>
        <meta name="description" content={metaDescription || ''} data-react-helmet="true"/>
        <meta 
          name="keywords" 
          content={`${primaryKeywords || ''}${primaryKeywords && secondaryKeywords ? ', ' : ''}${secondaryKeywords || ''}`} 
          data-react-helmet="true"
        />        
        <meta name="robots" content={seo?.metaRobots || 'index, follow'} data-react-helmet="true"/>
        <meta name="viewport" content={seo?.metaViewport || 'width=device-width, initial-scale=1'} data-react-helmet="true"/>
        <link rel="canonical" href={seo?.canonicalURL}/>
      </Helmet>
      <h1>{H1}</h1>
      <h2>{H2}</h2>
      <div className="content-description">
        {renderDescription(description)}
      </div>
    </div>
  );
};

export default ContentDetails;

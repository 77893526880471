import { Button } from '@mui/material';
import React from 'react';
import "../pages/BasicChat.css"

const OpenWhatsAppButton = () => {
  const openWhatsApp = () => {
    const phoneNumber = '919718134978'; // WhatsApp phone number including country code without any plus sign (+)
    const url = `https://wa.me/${phoneNumber}`;
    
    // Open the WhatsApp URL
    window.location.href = url;
  };

  return (
    // <Button  startIcon={<img 
    //     src="/icons/whatsapp.png" 
    //     alt="WhatsApp" 
    //     style={{ width: '32px', textDecoration: 'none' }} 
    //   />} onClick={openWhatsApp}>Click to Open WhatsApp</Button>
    <div style={{textAlign:"center"}}><img 
    src="/icons/whatsapp.png" 
    alt="WhatsApp" 
    style={{ width: '52px', textDecoration: 'none' }} 
  /></div>
    
  );
};

export default OpenWhatsAppButton;

import { useEffect, useState } from 'react';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import dayjs from 'dayjs';
import "./BasicChat.css"
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

const MobileDateComponent = ({setHasEditedDateTime,edit=false}) => {

 


  // const [value, setValue] = useState(new Date());
  /**
   * 
   * @returns 
   * const dob = localStorage.getItem("dob");
    const tob = localStorage.getItem("tob");
    dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${dateValue.date()}/${dateValue.month()}/${dateValue.year()}`, "tob":`${value.getHours()}::${value.getMinutes()}`}});
   */
  //dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${dateValue.date()}/${dateValue.month()}/${dateValue.year()}`, "tob":`${value.getHours()}::${value.getMinutes()}`}});
  // const [dateValue, setDateValue] = useState(dayjs(new Date("20-03-1992")));
  // const [dateValue, setDateValue] = useState(dayjs(null,"DD-MM-YYYY 12:00:00"));
  // const [dateValue, setDateValue] = useState(dayjs().startOf('day').add(12, 'hour'));
  const defaultDateTime = () => {

    const dob = localStorage.getItem("dob");
    const tob = localStorage.getItem("tob");

    if(!!dob && !!tob){

      const [day, month, year] = dob.split('/');
      const [hours, minutes] = tob.split(':');

      return dayjs(`${year}-${month}-${day}T${hours}:${minutes}`)
    }
    else{
      return dayjs();
    }
  } 
  
  const [dateValue, setDateValue] = useState(defaultDateTime());


  //const [dateValue, setDateValue] = useState(dayjs(null));
  //2022-04-17T18:30
  const [{dob, tob}, dispatch] = useStateValue();

  

  const getHour = (h) => {
    if(h<10){
        return `0${h}`;
    }
    return h;
  }

  const getMin = (m) => {
    if(m<10){
        return `0${m}`;
    }
    return m;
  }

  const map = (monthNum) => {
    if(monthNum == 0) return "01";
    if(monthNum == 1) return "02";
    if(monthNum == 2) return "03";
    if(monthNum == 3) return "04";
    if(monthNum == 4) return "05";
    if(monthNum == 5) return "06";
    if(monthNum == 6) return "07";
    if(monthNum == 7) return "08";
    if(monthNum == 8) return "09";
    if(monthNum == 9) return "10";
    if(monthNum == 10) return "11";
    if(monthNum == 11) return "12";
  }

  const padToTwoDigits = (number) => {
    return number.toString().padStart(2, '0');
  };

  useEffect(()=>{
    dispatch({ type: actionTypes.SET_USER_DOB, dob: `${padToTwoDigits(dateValue.date())}/${padToTwoDigits(dateValue.month()+1)}/${dateValue.year()}` });
    dispatch({ type: actionTypes.SET_USER_TOB, tob: `${getHour(dateValue.hour())}:${getMin(dateValue.minute())}`});
    localStorage.setItem("dob", `${padToTwoDigits(dateValue.date())}/${padToTwoDigits(dateValue.month()+1)}/${dateValue.year()}` );
    localStorage.setItem("tob",  `${getHour(dateValue.hour())}:${getMin(dateValue.minute())}` );

  },[dateValue])

  useEffect(() => {

    if (dob !="" && tob !="") {
      const [day, month, year] = dob.split('/');
      const [hours, minutes] = tob.split(':');
      setDateValue(dayjs(`${year}-${month}-${day}T${hours}:${minutes}`));
      //store in cache 
      // localStorage.setItem("dateValue", `${year}-${month}-${day}T${hours}:${minutes}`);
    }
  }, []);

  return (
    <div style={{marginTop:"3%", marginBottom:"8.5%", fontSize:"large"}} >
         { !edit && <p style={{fontWeight:"500"}}>Enter Your Birth Date and Time</p>} 
         { edit && <p style={{fontWeight:"500"}}>Enter Your Birth Date and Time</p>}
          <br/>
        {/* <MobileDatePicker value={dateValue} onChange={(newDateValue) => setDateValue(newDateValue)} /> */}

           <MobileDateTimePicker style={{width:"93vw"}} value={dateValue} onChange={(newDateValue) => {
            setDateValue(newDateValue);
            setHasEditedDateTime(true);
           }} /> 
        

               
    </div>
  );
}

export default MobileDateComponent;






import React, { useEffect } from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../statemanagement/StateProvider";
import "./BasicChat.css"; //should be moved to Header.css
import { actionTypes } from "../statemanagement/reducer";
import RightSidebar from "../components/RightSidebar";
import { Button } from "@mui/material";

//AuthWrapperHook should also be pushed here
const Header = ({ showLine = true, showLogin = true, displayAI = true }) => {
    const [{ user }, dispatch] = useStateValue(); //header will always have access to the user object from state
    const navigate = useNavigate(); //navigation to other pages within the SPA

    const SignInRouteClicked = (_) => {
        window.gtag('event', 'SignInRoute');
        navigate("/login")
    }

    const AccountRouteClicked = (_) => {
        window.gtag('event', 'AccountRouteClicked');
        navigate("/login")
    }

    return (<div className="headerStyle" >
        <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
            <div>
                <div style={{ display: "flex", alignItems:"center" }} onClick={_ => { navigate("/"); dispatch({ type: actionTypes.UPDATE_HEADLINES, headlines: [] }); }}>
                    {/* <img src="/icons/horoscope.png" style={{ paddingRight: "3.5%", width:"50px", height:"50px" }} /> */}
                    <img src="/icons/horoscope.png" style={{ paddingRight: "3.5%", width:"45px", height:"45px" }} />
                    <div className="headerStyle_Label">
                        <h1 style={{ fontSize: "30px", fontWeight: 500, marginLeft: "2%", opacity: "0.9", width:"fit-content" }}>AstroSky</h1>
                        {/* <h5><i style={{opacity:"0.5", fontWeight:500}}>World's most powerful AI Astrologer</i></h5>  */}
                        { displayAI && <h5><i style={{opacity:"0.5", fontWeight:500, padding: "2px" }}>Powered by AI</i></h5>}
                        { !displayAI && <h5><i style={{padding: "2px", display:"none" }}>Powered by AI</i></h5>}
                    </div> 
                </div>
                {/* <div style={{marginTop: "10px"}}>
                    <h5><i style={{opacity:"0.5", fontWeight:900, padding: "2px", fontFamily: "Roboto", marginLeft: "4px"}}>Quick, Modern & Accurate!</i></h5>
                </div> */}
            </div>
            
            <div style={{ display: "flex"}}>
           
            {/* <RightSidebar /> */}

                {/* {!!localStorage.getItem("uid") && <RightSidebar userCred={userCred}/>} */}
                <RightSidebar />
                
                {/* { !localStorage.getItem("uid") && <Button style={{padding:"5px 15px 5px 15px", color:"black", textTransform:"capitalize", background:"rgb(255, 240, 0)",borderRadius:"26px"}} onClick={_=>{
                    navigate("/signin")
                }}><p style={{fontWeight:"500", fontSize:"small"}}>Sign In</p></Button> } */}
            
            </div>
            {/* 
            boxShadow:"rgba(213, 217, 217, 0.5) 0px 2px 5px 0px"
            {!user && showLogin && <Button style={{ fontSize: "0.74em", textTransform: "inherit", background: "black", opacity: "0.8", borderRadius: "18px" }} variant="contained" onClick={SignInRouteClicked}>Sign In</Button>}
            {!!user && <AccountCircleIcon style={{ fontSize: "xx-large" }} onClick={AccountRouteClicked} />} */}
        </div>
        {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
    </div>);
}

export default Header;



// const Header = (showLine = true) => {

//     const navigate = useNavigate();

//     return (<div className="container_header">
//         <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
//             <div style={{ display: "flex" }} onClick={_ => navigate("/")}>
//                 <AllInclusiveIcon style={{ width: "35px", height: "35px" }} />
//                 <h1 style={{ fontWeight: 500, marginLeft: "2%", opacity: "0.9", display: "flex" }}>ChatDragon.ai</h1>
//             </div>
//         </div>
//         {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
//     </div>);
// }

// export default Header;
import { Drawer } from "@mui/material"; //right panel which will be hidden
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import MailIcon from '@mui/icons-material/Mail';
import ArticleIcon from '@mui/icons-material/Article';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useStateValue } from "../statemanagement/StateProvider";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';

const RightSidebar = () => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [{ userCred }, _] = useStateValue();

    // useEffect(()=>{
    //     console.log("Right Sidebar is mounted");
    //     console.log("user cred is");
    //     console.log(userCred);
    // },[])




    //{text:"Refund & Cancellation", icon: <CancelIcon/>, nav: "/cancellation"}
    //{text:"Feedback/Lucky Draw", icon: <RateReviewIcon/>, nav: "/feedback", key:"feedback"},
    //{ text: "Sign In", icon: <LoginIcon />, nav: "/signin", key: "signin" }
    //{ text: "Refund Policy", icon: <ArticleIcon />, nav: "/cancellation", key: "cancellation" }
    //{ text: "Blogs", icon: <ArticleIcon />, nav: "/blog", key: "blogs" },
    const drawerMenu = () => {
        const drawerMenu = [{ text: "Terms & Conditions", icon: <ArticleIcon />, nav: "/terms-of-service", key: "terms" },{ text: "Blogs", icon: <ArticleIcon />, nav: "/blogs", key: "blogs" },
        { text: "Privacy Policy", icon: <ArticleIcon />, nav: "/privacy", key: "privacy" }, { text: "Support", icon: <ContactSupportIcon />, nav: "/contact-us", key: "support" }, {text:"Cancellation", icon: <CancelIcon/>, nav: "/cancellation"}];
        // return drawerMenu;
        return !!userCred ? [{ text: "My Profile", icon: <AccountBoxIcon />, nav: "/profile", key: "profile" }, ...drawerMenu] : [{text:"Feedback/Lucky Draw", icon: <RateReviewIcon/>, nav: "/feedback", key:"feedback"}, ...drawerMenu];
    }

    return (


        <div>


            <Button startIcon={<MenuIcon style={{ color: "black", fontSize: "1.5rem" }} />} onClick={(e) => {
                setOpen(true);
            }}></Button>
            <Drawer onClose={(e) => {
                setOpen(false);
            }} anchor="right" open={open}>

                <><ListItem key={"home"} disablePadding>
                    <ListItemButton onClick={(e) => navigate("/")}>
                        <ListItemIcon>
                            <img src="/icons/horoscope.png" style={{ paddingRight: "3.5%", width: "30px", height: "30px" }} />
                        </ListItemIcon>
                        <ListItemText>
                            AstroSky
                        </ListItemText>
                    </ListItemButton>
                </ListItem><Divider /></>

                {


                    drawerMenu().map((u) => {
                        return (<ListItem key={u.key} disablePadding>
                            <ListItemButton onClick={(e) => navigate(u.nav)}>
                                <ListItemIcon>
                                    {u.icon}
                                </ListItemIcon>
                                <ListItemText>
                                    {u.text}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>);
                    })
                }
            </Drawer>
        </div>


    );
}

export default RightSidebar;
import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import AppFooter from './AppFooter';
import "./BasicChat.css";
import { useStateValue } from '../statemanagement/StateProvider';
import Disclaimer from '../components/Disclaimer';
import UseBirthDetails from './UseBirthDetails';
import BirthDetailsDisplayComponent from '../components/BirthDetailsDisplayComponent';
import Entity from './Entity';
import axios from "axios";
import { Box, IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { actionTypes } from '../statemanagement/reducer';
import LiveDashas from './LiveDashas';
import { getUrl } from '../helpers/envUrls';
import ReportCard from './ReportCard';
import ReportView from './ReportView';

const Reports = () => {

    UseBirthDetails();
    const [{ userCred, dob, tob, city, horoscope }, dispatch] = useStateValue();
    const [entities, setEntities] = useState([]);
    const prodUrl = "https://astroindia.azurewebsites.net/";
    const localUrl = "http://localhost:8090/";
    const uatUrl = "https://astroservice-uat.azurewebsites.net/";

    useEffect(()=>{

        dispatch({type:actionTypes.RESET_STATE}); 
        dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []});
        
    },[])

    useEffect(()=> {
        
        const getEntities = async () => {
            const entityJSON = await axios.get(`${getUrl()}/entities`);
            //console.log(entityJSON.data);
            setEntities(entityJSON.data);
        }

        getEntities();

    },[])

    const EntitiesElem = entities.map((u)=>{
        return (<><Entity entityImageSrc={u.entityImageSrc}
                           entityName={u.entityName}
                           entityDescription={u.entityDescription}
                           entityRating={u.entityRating} 
                           entityCode={u.entityCode}
                           samepleQuestions={u.sampleQuestions.join(", ")} />

                            <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} /></>)
    })

    return (
        <>
            <div>
                <div className="container" style={{ padding: "3.9%" }}>
                    <Header userCred={userCred} showLogin={false} />
                    <br/>
                    <BirthDetailsDisplayComponent dob={dob} tob={tob} city={city} />
                    
                    <ReportView/>
                    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                    {/* <div style={{ marginTop: "2%" }}>
                        <div style={{ textAlign: "center" }}>
                        <BirthDetailsDisplayComponent dob={dob} tob={tob} city={city} />
                        
                          <LiveDashas horoscope={horoscope}/>

                            {!!horoscope &&  <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
    
                        <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/entity-002.webp"}
                           entityName={"Career Report"}
                           entityDescription={"1 year Career Report, Top Rated"}
                           entityRating={4.5} ppm={3}
                           entityCode={"sunny"}
                           samepleQuestions={"Comprehensive Career Horoscope, Remedies & Mantras to attain high post, Remedies & Mantras for success at work, Optimal period to switch job, Optimal period for promotion"} />
                         
                            <Disclaimer hindi={false} />
                            <br/><br/><br/><br/> <br/><br/><br/><br/>
                            <br/><br/><br/><br/> <br/><br/><br/><br/>
                            
                        </div>
                    </div> */}
                </div>
               
            </div>
            <AppFooter home={true} more={false} />
        </>
    );
};

export default Reports;

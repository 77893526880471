import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../pages/BasicChat.css";
import { TypeAnimation } from "react-type-animation";

const BirthDetailsDisplayComponent = ({dob, tob, city}) => { //from state

    const navigate = useNavigate();

    const truncatedCity = city && city.length > 12 ? city.substring(0, 12) + '...' : city;

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        const monthName = date.toLocaleString('default', { month: 'long' });
        return `${parseInt(day)}${getOrdinal(day)} ${monthName} ${year}`;
    };

    const getOrdinal = (n) => {
        const s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return (s[(v - 20) % 10] || s[v] || s[0]);
    };

    const formattedDob = dob ? formatDate(dob) : "";

    /**
     *   <TypeAnimation sequence={[
                            '5 Million+ Questions Answered',
                        ]} wrapper="span" speed={10} style={{ fontSize: "1.2em", fontWeight:"450", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0} />
     */
    if (!!dob && !!tob && !! city) {

        return  (<Accordion sx={{ boxShadow: 'none', fontSize:"0.9rem", marginTop:"5%", marginBottom:"5%", textAlign:"center", border:"none" }}>
        <AccordionSummary expandIcon={<ModeEditIcon style={{ color: "black" }} />}>
            <span style={{ fontWeight: "500" }}>{formattedDob} | {tob} | {truncatedCity}</span>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{ fontWeight: "400", fontSize: "1rem", textAlign: "left", fontWeight: "500 !important" }}>
                <div style={{ marginBottom: "5px", fontWeight: "400", marginBottom: "15px" }}>Location of Birth: <strong>{city}</strong> </div>
                <div style={{ marginBottom: "5px", fontWeight: "400", marginBottom: "15px" }}>Date of Birth: <strong>{formattedDob}</strong></div>
                <div style={{ marginBottom: "5px", fontWeight: "400", marginBottom: "15px" }}>Time of Birth: <strong>{tob}</strong></div>
                <br />
            </div>

            <Button startIcon={<ModeEditIcon />} className='discoverChatStyleChit' onClick={(e) => navigate("/edit")}>Edit</Button>
            <br /><br /><br />
        </AccordionDetails>
    </Accordion>);
    } 
}

export default BirthDetailsDisplayComponent;


import AppFooter from "./AppFooter";
import { ContactUs } from "./ContactUs";
import Header from "./Header";


const Order = () => {
    return (<div>
        <div className="container" style={{padding:"2.9%"}}>
            <Header showLogin={false}/>
            <ContactUs/>
            <br/>
            <br/>
            <br/>
            <br/>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} />
        </div> 
    </div>);
}

export default Order;
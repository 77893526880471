import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './dropdownMenu.css';
const DropdownMenu = ({ title, items }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="dropdown-menu">
            <Button
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                className="dropdown-title"
            >
                {title}
            </Button>
            <Menu
                id="menu-list-grow"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    onMouseEnter: () => setAnchorEl(anchorEl),
                    onMouseLeave: handleClose,
                }}
                className="dropdown-menu-list"
            >
                {items.map((item) => (
                    <MenuItem key={item.text} onClick={handleClose} component="a" href={item.href} className="dropdown-item">
                        {item.text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
export default DropdownMenu;
